import { useState } from "react";

import ContentContainer from "../../components/contentContainer";
import RoundedButton from "../../components/roundedButton";
import { Selection } from "../../components/select";
import Modal from "../../components/modal";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";

import { IWebDetailsResponse } from "../../services/web-details";

const WebsiteSelection = ({
  openNewCampaignVerificationModal,
  setOpenNewCampaignVerificationModal,
  selectedListing,
  selectedSiteData,
  tempSelectedWebsite,
  setTempSelectedWebsite,
  setSelectedListing,
  // setAdvertiseFlowId,
  selectionData,
  handleWebsiteNext,
  handleCreateNewCampaign,
  canMoveToWizardStep,
  isCreatingCampaignError,
  isCreateBudgetFailed,
  isAdImagesFailed,
}: {
  openNewCampaignVerificationModal: boolean;
  setOpenNewCampaignVerificationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedListing: any;
  selectedSiteData: IWebDetailsResponse | undefined;
  tempSelectedWebsite: any;
  setTempSelectedWebsite: React.Dispatch<React.SetStateAction<any>>;
  setSelectedListing: React.Dispatch<React.SetStateAction<any>>;
  // setAdvertiseFlowId: React.Dispatch<React.SetStateAction<string>>;
  selectionData:
    | {
        id: any;
        value: any;
        label: any;
      }[]
    | undefined;
  handleWebsiteNext(): Promise<void>;
  handleCreateNewCampaign(): Promise<void>;
  canMoveToWizardStep: boolean;
  isCreatingCampaignError: boolean;
  isCreateBudgetFailed: boolean;
  isAdImagesFailed: boolean;
}) => {
  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);

  function handleSave() {
    setTempSelectedWebsite({ ...selectedListing });
  }

  function handleBack() {
    setSelectedListing({ ...tempSelectedWebsite });
  }

  return (
    <div>
      {openNewCampaignVerificationModal && (
        <Modal
          setIsModalOpen={setOpenNewCampaignVerificationModal}
          modalBodyContent={
            <div className="flex flex-col max-w-lg py-1 px-2 my-2 mx-1">
              <p className="font-interSemiBold leading-7  text-primary">
                It looks like you already have an active campaign with this
                website — it’s exciting that you’re eager to create more. Are
                you sure you want to go ahead and launch a brand-new campaign?
              </p>
              <div className="flex mt-8 justify-center">
                <RoundedButton
                  text="yes"
                  className="py-2 px-6 ManropeRegular mr-2"
                  onClick={async () => {
                    handleCreateNewCampaign();
                    setOpenNewCampaignVerificationModal(false);
                  }}
                />
                <RoundedButton
                  text="no"
                  className="py-2 px-6 font-ManropeRegular bg-gray-300"
                  onClick={() => {
                    setOpenNewCampaignVerificationModal(false);
                  }}
                />
              </div>
            </div>
          }
        />
      )}
      <ContentContainer className="p-7">
        <div className=" min-h-[30.125rem] max-h-[50rem]">
          {!isEditEnabled && (
            <RoundedButton
              type="button"
              onClick={() => setIsEditEnabled(!isEditEnabled)}
              className="py-2.5 px-4 max-w-fit ml-auto"
              borderRadius="rounded-md"
              text={
                <p className=" text-sm text-white inline-flex items-center font-interRegular">
                  <EditIcon className="text-white fill-current mr-2 text-2xl " />
                  EDIT
                </p>
              }
            />
          )}
          <div className=" flex flex-1 flex-col">
            <div className="flex flex-1 flex-col w-[70%] h-fit ">
              <div className="flex  flex-col space-y-8">
                {!isEditEnabled ? (
                  <>
                    <p className="text-sm text-primary font-interRegula">
                      Ready to run your next ad campaign for the
                      <span className="font-interSemiBold mx-1">
                        {selectedSiteData?.shortName}
                      </span>
                      ?
                    </p>
                    {selectedSiteData?.id && !canMoveToWizardStep ? (
                      <>
                        <p className="text-colorDelete text-sm font-interSemiBold  ">
                          Oops it looks like you’re missing some information for
                          <span className="font-interBold mx-2">
                            {selectedSiteData.shortName}
                          </span>
                          <span className="">
                            . Go to Websites on the left navigation and ensure
                            the the selected website has at least one asset and
                            all the information fields are filled
                          </span>
                        </p>
                      </>
                    ) : (
                      <p className="text-sm text-primary font-interRegular">
                        Click 'Next' to discover your custom strategy, creative
                        ads, and real-time optimization—all designed to make
                        your brand shine.
                      </p>
                    )}
                    <p className="text-sm text-primary font-interRegular">
                      Not the right website? Click the button “Edit”
                    </p>
                  </>
                ) : (
                  <div className="xs:w-[18.75rem] sm:w-[27.5rem]">
                    <p className="font-interRegular text-sm text-secondary mb-1">
                      What website are you looking to advertise for?
                    </p>
                    <Selection
                      data={selectionData}
                      value={selectedListing}
                      closeMenuOnSelect={true}
                      isMulti={undefined}
                      placeholder={"Select a website"}
                      customStyle={{
                        selectContainer: {
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: "#40444f",
                          borderRadius: "0.625rem",
                          width: "100%",
                          fontSize: 14,
                          marginTop: "1px",
                        },
                        valueContainer: {
                          paddingInline: "0.75rem",
                          paddingBlock: "0.43rem",
                        },
                        color: "#d3d3d3",
                        optionisSelectedBgColor: "transparent",
                        optionisFocusedBgColor: "#d7edff",
                        optionisFocusedTextColor: "#0e73f6",
                        singleValStyles: {
                          backgroundColor: "transparent",
                        },
                      }}
                      onChange={(selectedOption: any, actionMeta: any) => {
                        // setAdvertiseFlowId(selectedOption.id);
                        setSelectedListing(selectedOption);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="flex mt-20  flex-col">
                {isCreatingCampaignError && (
                  <div>
                    <p className="text-colorDelete text-sm font-interSemiBold">
                      Something went wrong with creating campaign!
                    </p>
                  </div>
                )}
                {isCreateBudgetFailed && (
                  <div className="mt-2">
                    <p className="text-colorDelete text-sm font-interSemiBold">
                      Something went wrong with creating budget!
                    </p>
                  </div>
                )}
                {isAdImagesFailed && (
                  <div className="mt-2">
                    <p className="text-colorDelete text-sm font-interSemiBold">
                      Something went wrong with creating ad images!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex ">
          {isEditEnabled && (
            <div className="flex items-center">
              <RoundedButton
                type="submit"
                className="py-2.5 px-4"
                borderRadius="rounded-md"
                onClick={() => {
                  handleSave();
                  setIsEditEnabled(false);
                }}
                text={
                  <p className=" text-sm text-white inline-flex items-center font-interRegular">
                    <EditIcon className="text-white fill-current mr-2 text-2xl " />
                    SAVE
                  </p>
                }
              />
              <p
                onClick={() => {
                  handleBack();
                  setIsEditEnabled(false);
                }}
                className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
              >
                Cancel
              </p>
            </div>
          )}
          {!isEditEnabled && (
            <div className="ml-auto">
              <RoundedButton
                onClick={handleWebsiteNext}
                type="button"
                isEnabled={canMoveToWizardStep}
                className="py-2.5 px-9 max-w-fit max-h-fit ml-auto mt-auto"
                borderRadius="rounded-md"
                text={
                  <p className=" text-sm text-white inline-flex items-center font-interRegular">
                    Next
                  </p>
                }
              />
            </div>
          )}
        </div>
      </ContentContainer>
    </div>
  );
};

export default WebsiteSelection;
