import { useStep } from "../../../../../contexts/stepper";
import { useCampaign } from "../../../../../contexts/campaign";
import { useSelectedAdvertiser } from "../../../../../contexts/selectedAdvertiser";

import RoundedButton from "../../../../../components/roundedButton";
import { Selection } from "../../../../../components/select";

import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-icon-mui.svg";

import { IBannerBearImagesResponse } from "../../../../../services/wizard/bannerbear";
import { useSingleCampaignDetails } from "../../../../../services/campaigns";

import { ICreativeDisplayModeProps } from "../../../../../interfaces";

const CreativeDisplayMode = ({
  formattedSelectionData,
  isBudgetChannelPending,
  adCategoryselection,
  setAdCategorySelection,
  adsFromEach,
  previewedImage,
  setPreviewedImage,
  setModalImage,
  isEditEnabled,
  setIsEditEnabled,
}: ICreativeDisplayModeProps) => {
  const { setStep } = useStep();
  const { campaignId } = useCampaign();
  const { selectedAdvertiser } = useSelectedAdvertiser();

  const { data: campaignDetailsData } = useSingleCampaignDetails(
    campaignId,
    selectedAdvertiser
  );

  function handlePreviewedImage(id: number, imageUrl: string | null) {
    setPreviewedImage({
      id,
      imageUrl,
    });
  }

  const adCategorySelectionMapping =
    adCategoryselection?.value === "display"
      ? "displayAds"
      : adCategoryselection?.value === "social"
      ? "socialAds"
      : adCategoryselection?.value === "search" && "searchAds";

  return (
    <>
      <div>
        <p className="text-primary font-interRegular text-sm leading-6">
          These creative offer a glimpse of how your brand can engage with
          audiences across different media channels. Each creative is carefully
          tailored to reflect your unique brand identity and objectives,
          leveraging best practices to ensure maximum impact. There’s no need to
          worry—our system continuously optimizes for you. If any creative under
          performs for whatever reason, it will be automatically replaced to
          ensure you're always putting your best foot forward.
        </p>
      </div>
      <hr className=" mt-1.5 mb-3 -mx-4" />

      <div className="flex">
        <div className="w-[200px] flex-col h-[566px] overscroll-y-auto hide-scrollbar  px-2 py-2 flex border border-solid border-[#E5E9EB] rounded-md">
          <div className="w-full mb-2">
            {formattedSelectionData && (
              <Selection
                data={formattedSelectionData ?? []}
                isPending={isBudgetChannelPending}
                value={adCategoryselection}
                closeMenuOnSelect={true}
                isMulti={undefined}
                onChange={(selectedOption: any, actionMeta: any) => {
                  setAdCategorySelection(selectedOption);
                }}
                customStyle={{
                  selectContainer: {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#40444f",
                    borderRadius: "0.625rem",
                    width: "100%",
                    fontSize: 14,
                    marginTop: "1px",
                  },
                  color: "#d3d3d3",
                  valueContainer: {
                    paddingInline: "0.45rem",
                    paddingBlock: "0.1rem",
                  },
                  optionisSelectedBgColor: "transparent",
                  optionisFocusedBgColor: "#d7edff",
                  optionisFocusedTextColor: "#0e73f6",
                  singleValStyles: {
                    fontSize: 14,
                    backgroundColor: "transparent",
                  },
                  multiValStyles: {
                    backgroundColor: "#d7edff",
                    color: "#0e73f6",
                  },
                  multiValLabelStyles: {
                    color: "#0e73f6",
                  },
                }}
              />
            )}
          </div>

          {adsFromEach &&
            adsFromEach[
              adCategoryselection?.value as keyof IBannerBearImagesResponse
            ]?.length !== 0 && (
              <div className="flex flex-col h-full overflow-y-auto hide-scrollbar   gap-y-4 ">
                {adsFromEach[
                  adCategorySelectionMapping as keyof IBannerBearImagesResponse
                ]?.map((ad) => {
                  return (
                    <div
                      onClick={() => handlePreviewedImage(ad.id, ad.imageUrl)}
                      className={`${
                        previewedImage?.id === ad.id && "bg-[#D7EDFF]"
                      } cursor-pointer w-full -mb-4 last-of-type:mb-0 p-2`}
                      key={ad.id}
                    >
                      {ad.imageUrl && (
                        <img
                          className="object-contain max-h-[300px]"
                          src={ad.imageUrl}
                          alt={ad.templateType}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
        </div>

        {previewedImage && previewedImage?.id && previewedImage.imageUrl ? (
          <div className="flex items-center justify-center mx-auto">
            <div>
              <img
                onClick={() => setModalImage(previewedImage.imageUrl)}
                className="object-contain cursor-pointer max-h-[25rem] min-w-[350px]"
                src={previewedImage.imageUrl}
                alt="previewed-item"
              />
              <p className="mt-3 text-primary text-xs font-interRegular text-center">
                Click to zoom{" "}
              </p>
            </div>
          </div>
        ) : (
          <div className="max-w-[80%] px-6 mt-2">
            <p className="text-primary  text-sm  font-interSemiBold">
              No creative is needed for this channel since no budget was
              allocated. Adjust your budget if you'd like to see some sample
              creatives for this channel
            </p>
          </div>
        )}

        {!isEditEnabled &&
          campaignDetailsData?.status &&
          campaignDetailsData?.status !== "active" && (
            <RoundedButton
              type="button"
              onClick={() => {
                if (
                  campaignDetailsData?.status &&
                  campaignDetailsData?.status !== "active"
                ) {
                  setIsEditEnabled(!isEditEnabled);
                }
              }}
              className="py-2.5 px-4 max-w-fit ml-auto max-h-[2.9rem]"
              borderRadius="rounded-md"
              text={
                <p className=" text-sm text-white inline-flex items-center font-interRegular">
                  <EditIcon className="text-white fill-current mr-2 text-2xl " />
                  EDIT
                </p>
              }
            />
          )}
      </div>

      <div className="ml-auto flex ">
        <RoundedButton
          onClick={() => setStep(2)}
          type="button"
          className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto mr-5"
          borderRadius="rounded-md"
          bgColor="#FFFFFF"
          borderColor="#C1C9D2"
          text={
            <p className=" text-sm text-primary inline-flex items-center font-interRegular">
              Previous
            </p>
          }
        />
        <RoundedButton
          onClick={() => setStep(4)}
          type="button"
          isEnabled={true}
          className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
          borderRadius="rounded-md"
          text={
            <p className=" text-sm text-white inline-flex items-center font-interRegular">
              Next
            </p>
          }
        />
      </div>
    </>
  );
};

export default CreativeDisplayMode;
