import ProgressGif from "../../assets/gifs/ProgressBarProcessing.gif";
import ProgressGifStatus from "../../assets/gifs/ProgressBarStatus.gif";
import { useToggleSidebar } from "../../contexts/toggleSidebar";

const WithGif = () => {
  const { isSidebarOpen } = useToggleSidebar();

  return (
    <div className="fixed inset-0 z-30 bg-white/30 backdrop-blur-sm flex items-center ml-5">
      <div
        className={`flex flex-col mt-10 ${
          isSidebarOpen ? " md:ml-[16rem]" : "ml-0"
        }   `}
      >
        <img
          alt="progress-gif"
          src={ProgressGif}
          className={`h-full w-full max-w-[80rem]  `}
        />
        <img
          alt="progress-gif-status"
          src={ProgressGifStatus}
          className="h-[15rem] mt-6 object-contain"
        />
      </div>
    </div>
  );
};

export default WithGif;
