/* GET campaign budget channels under organization*/

import { useQuery } from "@tanstack/react-query";
import { request } from "../../axios";

export interface IBudgetOrganizationChannelsResponse {
  organizationId: number;
  budgetChannel: "social" | "search" | "display" | "paidEmail";
  channelName: string;
}

function getOrganizationChannel(
  organizationId: number | null
): Promise<IBudgetOrganizationChannelsResponse[] | []> {
  if (organizationId) {
    return request({
      method: "get",
      url: `organization-budget-channel/${organizationId}`,
    });
  }
  return request({
    method: "get",
    url: `organization-budget-channel/null`,
  });
}

export function useBudgetOrganizationChannels(
  organizationId: number | null,
  domainName: string | null
) {
  return useQuery({
    queryKey: ["budgetOrganizationChannels", organizationId],
    queryFn: () => getOrganizationChannel(organizationId),
    enabled: !!domainName,
  });
}
