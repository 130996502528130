import Tooltip from "../../components/tooltip";

import { ReactComponent as SuccessIcon } from "../../assets/icons/check-circle-mui.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning-mui.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error-mui.svg";

import { isOlderThanSpecifiedMinutes } from "../../utils/isOlderThanSpecifiedMinutes";

const WebsiteStatusColumnRenderer = ({ params }: { params: any }) => {
  const { isEditable, numberOfAssets, isFieldsFilled, updatedAt } = params.data;

  return (isEditable && numberOfAssets === 0) || isFieldsFilled === false ? (
    <div className="flex items-center">
      <div className="mr-1.5">
        <Tooltip
          Icon={WarningIcon}
          bodyText="Oops, looks like we missed some information. Please edit the website details manually"
          className="w-7 h-9 text-colorOrange fill-current cursor-pointer"
        />
        {/* <WarningIcon className="w-7 h-9 text-gray-500 fill-current" /> */}
      </div>
      <p className="text-primary text-sm font-interRegular capitalize">
        Missing Info
      </p>
    </div>
  ) : !isEditable && !isOlderThanSpecifiedMinutes(updatedAt) ? (
    <div className="flex items-center">
      <div className="flex justify-center mr-1.5 items-center">
        <div className="animate-spin rounded-full w-6 h-6 border-t-[2.5px] border-b-[2.5px] border-[#f48535]"></div>
      </div>
      <p className="text-primary  text-sm font-interRegular capitalize">
        Processing...
      </p>
    </div>
  ) : !isEditable && isOlderThanSpecifiedMinutes(updatedAt) ? (
    <div className="flex items-center">
      <div className="mr-1.5">
        <ErrorIcon className="w-7 h-9 text-colorDelete fill-current" />
      </div>
      <p className="text-primary text-sm font-interRegular capitalize">Error</p>
    </div>
  ) : (
    isEditable &&
    numberOfAssets !== 0 &&
    isFieldsFilled && (
      <div className="flex items-center">
        <div className="mr-1.5">
          <SuccessIcon className="w-7 h-9 text-colorGreen fill-current" />
        </div>
        <p className="text-primary text-sm font-interRegular capitalize">
          Ready
        </p>
      </div>
    )
  );
};

export default WebsiteStatusColumnRenderer;
