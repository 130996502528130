import { useQuery } from "@tanstack/react-query";
import { request } from "../axios";

/** GET All website types associated with user industry */
const getWebsiteTypes = (industryId: number | undefined) => {
  if (industryId) {
    return request({
      url: `website-types/industry/${industryId}`,
      method: "get",
    });
  }
  return;
};
export const useWebsiteTypes = (
  industryId: number | undefined,
  selectedAdvertiser: number | null
) => {
  return useQuery({
    queryKey: ["websiteTypes", selectedAdvertiser],
    queryFn: () => getWebsiteTypes(industryId),
    enabled: !!industryId,
  });
};

interface IWebsiteTypeResponse {
  id: number;
  name: string;
  industryId: number;
  createdAt: Date;
  updatedAt: Date;
}
/** GET website  type by id*/
const getWebsiteType = (
  websiteTypeId: number | null
): Promise<IWebsiteTypeResponse> => {
  return request({
    url: `website-types/${websiteTypeId}`,
    method: "get",
  });
};

export const useWebsiteType = (
  siteId: number,
  websiteTypeId: number | null
) => {
  return useQuery({
    queryKey: ["websiteType", siteId],
    queryFn: () => getWebsiteType(websiteTypeId),
    enabled: !!siteId || !!websiteTypeId,
  });
};
