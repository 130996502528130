import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import { queryClient } from "../../App";

export interface ICreateNewAssetRequest {
  websiteId: number;
  name: string;
  imageUrl: string;
  isExcluded: boolean;
}

export interface IUpdateAssetRequest {
  assets: {
    id: number;
    isExcluded: boolean;
  }[];
}

/** GET one websites assets with website id */
const getWebsiteAssets = (websiteId: number | null) => {
  if (websiteId) {
    return request({
      url: `website-asset/website/${websiteId}`,
      method: "get",
    });
  }
  return;
};
export const useWebsiteAssets = (websiteId: number | null) => {
  return useQuery({
    queryKey: ["websiteAssets", websiteId],
    queryFn: () => getWebsiteAssets(websiteId),
    enabled: !!websiteId,
  });
};

/* Generate a new asset linked to a url */
const addAsset = (data: ICreateNewAssetRequest) => {
  return request({
    url: "website-asset/",
    method: "post",
    data,
  });
};

export const useAddAsset = (websiteId: number | null) =>
  useMutation({
    mutationFn: addAsset,
    onSuccess: (result) => {
      queryClient.setQueryData(["addAsset"], result.data);
      queryClient.invalidateQueries({
        queryKey: ["websiteAssets", websiteId],
      });
    },
  });

/* Update an asset linked to a url */
const updateAsset = (data: IUpdateAssetRequest) => {
  return request({
    url: "website-asset/update",
    method: "post",
    data,
  });
};

export const useUpdateAsset = (websiteId: number | null) =>
  useMutation({
    mutationFn: updateAsset,
    onSuccess: (result) => {
      queryClient.setQueryData(["addAsset"], result.data);
      queryClient.invalidateQueries({
        queryKey: ["websiteAssets", websiteId],
      });
    },
  });
