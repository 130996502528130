import React, { useMemo } from "react";

import { UseMutateAsyncFunction } from "@tanstack/react-query";

import { isValidPhoneNumber } from "libphonenumber-js";

import { useForm } from "react-hook-form";

import { useAuth } from "../../../contexts/auth";

import ContentContainer from "../../../components/contentContainer";
import Input from "../../../components/input/inputWithToggle";
import RoundedButton from "../../../components/roundedButton";
import TopContent from "../../../components/topContent";

import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon-mui.svg";

import { IUserData, ProfileContent } from "../../../interfaces";

interface EditProfileProps {
  isUpdateUserPending: boolean;
  updateUserProfile: UseMutateAsyncFunction<any, Error, IUserData, unknown>;
  data: {
    displayName: string;
    email: string;
    phoneNumber: string;
  };
  onClickGoBack: (content: ProfileContent) => void;
}

type TUserData = EditProfileProps["data"];

const EditProfile: React.FC<EditProfileProps> = ({
  data,
  onClickGoBack,
  isUpdateUserPending,
  updateUserProfile,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<TUserData>({
    defaultValues: data,
  });

  const userData = watch();

  const { clearAuthStates } = useAuth();

  async function handleUpdateUserProfile() {
    await updateUserProfile({
      displayName: userData.displayName,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
    });
    onClickGoBack("displayProfile");
  }

  function handleClickGoBack() {
    onClickGoBack("displayProfile");
    // we need to clearAuthStates manually here as there is no route change happening between displayProfile and editProfile interfaces
    clearAuthStates();
  }

  const phoneNumberValidationMsg = useMemo(
    () =>
      !userData.phoneNumber
        ? undefined
        : isValidPhoneNumber(userData.phoneNumber)
        ? undefined
        : "Phone number is not valid",
    [userData.phoneNumber]
  );

  function onSubmit() {
    handleUpdateUserProfile();
  }

  return (
    <div className="flex flex-col">
      <TopContent
        title="User Profile"
        subTitle="Manage your personal information"
      />
      <ContentContainer>
        <div className=" flex flex-1 flex-col p-12 pb-0 ">
          <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-1 flex-col w-[600px] space-y-6">
                <Input
                  type="text"
                  name="displayName"
                  label="Name"
                  register={register}
                  requiredMessage="Name is required"
                  validation={errors.displayName?.message}
                  reactHooksFormEnabled={true}
                  validationClassName="text-colorDelete text-sm  ml-2"
                  className={`${
                    errors.displayName?.message && "border-[#D34638] "
                  }   `}
                />

                <Input
                  type="text"
                  name="email"
                  label="Email"
                  register={register}
                  pattern={{
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Entered value does not match email format",
                  }}
                  requiredMessage="Email is required"
                  validation={errors.email?.message}
                  validationClassName="text-colorDelete text-sm  ml-2"
                  reactHooksFormEnabled={true}
                  className={`${errors.email?.message && "border-[#D34638]"}`}
                />
                <Input
                  type="text"
                  name="phoneNumber"
                  label="Phone Number"
                  className={`${
                    (errors.phoneNumber?.message || phoneNumberValidationMsg) &&
                    "border-[#D34638]"
                  }
                    `}
                  register={register}
                  requiredMessage="Phone number is required"
                  validation={
                    errors.phoneNumber?.message || phoneNumberValidationMsg
                  }
                  validationClassName="text-colorDelete text-sm  ml-2"
                  reactHooksFormEnabled={true}
                />
              </div>
              <div className=" flex  pt-10 items-center">
                <RoundedButton
                  className="py-2.5 px-4"
                  type="submit"
                  borderRadius="rounded-md"
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      <EditIcon className="text-white fill-current mr-2 text-2xl " />
                      {isUpdateUserPending ? "Saving..." : "SAVE"}
                    </p>
                  }
                />
                <p
                  onClick={handleClickGoBack}
                  className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
                >
                  Cancel
                </p>
              </div>
            </form>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default EditProfile;
