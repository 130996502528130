import MainLayout from "../../../layouts/main";
import Assets from "../../../containers/websites/editAssets";

const Index = () => {
  return (
    <MainLayout showSidebarLogo showSidebar>
      <Assets />
    </MainLayout>
  );
};

export default Index;
