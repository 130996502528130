import { useCampaign } from "../../../../contexts/campaign";
import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";

import { categorizeFields } from "./categorizeFields";
import AddressMap from "../../../map";

import Tooltip from "../../../../components/tooltip";

import { useGeocoding } from "../../../../hooks/useGeoCoding";

import { ReactComponent as TooltipIcon } from "../../../../assets/icons/tooltip-icon-mui.svg";

import { parseDefaultVal } from "../../../../utils/parseDefaultValues";

import { useCampaignFields } from "../../../../services/industry-organization-campaign-fields";

const CampaignFieldsReadMode = ({
  formattedKeywords,
  isUpdatingKeywordsFailed,
  numberOfKeywords,
}: {
  isUpdatingKeywordsFailed: boolean;
  formattedKeywords: string | undefined;
  numberOfKeywords: number | null;
}) => {
  const { campaignId } = useCampaign();
  const { selectedAdvertiser: advertiserId } = useSelectedAdvertiser();

  const { data: campaignFields } = useCampaignFields(advertiserId, campaignId);

  const categorizedFields =
    campaignFields && categorizeFields([...campaignFields]);

  const ADVNACED_TARGETING_DATA =
    categorizedFields && categorizedFields["ADVANCE_TARGETING"];

  const GEO_TARGETING_DATA =
    categorizedFields && categorizedFields["GEO_TARGETING"];

  const addressField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "address"
  );

  const radiusField = GEO_TARGETING_DATA?.find(
    (field) => field.campaignFieldName.toLowerCase() === "radius"
  );

  const { geoCodingResult } = useGeocoding({ address: addressField?.value });

  const isIdealKeywordCount =
    numberOfKeywords && numberOfKeywords >= 15 && numberOfKeywords <= 25;

  return (
    <div className=" flex flex-col my-7">
      <div className="flex flex-col w-full">
        <div className="w-[65%]">
          <h3 className="text-primary font-interSemiBold text-sm">Summary</h3>
          <p className="text-sm text-primary mt-1.5 font-interRegular">
            These parameters are set to maximize your campaign impact and reach
            effectively. You can change these settings by clicking “Edit” though
            it is recommended to keep them as these are based on your industry
            best practices to ensure optimal result for your campaign.
          </p>
        </div>

        <div className="flex w-full mt-12 mb-4">
          <div className="flex flex-col w-[65%]">
            <div className="flex  items-center w-full">
              <h3 className="text-primary font-interSemiBold text-sm mr-1.5 ">
                Audience
              </h3>
              <Tooltip
                Icon={TooltipIcon}
                className="cursor-pointer w-4 h-4 text-gray-500 fill-current self-end"
                bodyText="The settings allow you to deliver ads to people based on their geographic location and traits. This strategy helps ensure your ads reach the right audience in the right place, making your campaigns more relevant and effective"
              />
            </div>
            <p className="text-sm text-primary mt-1.5 font-interRegular">
              To ensure that your ads are reaching the right audience in the
              right places, ads will be shown around
              <span className="font-interSemiBold mx-1.5">
                {addressField?.value}
              </span>
              for a
              <span className="font-interSemiBold mx-1.5">
                {radiusField?.value}
              </span>
              mile radius targeted for audiences that meet the following traits
            </p>
            <div className="flex flex-col mt-2">
              {ADVNACED_TARGETING_DATA?.map((field) => {
                return (
                  <div key={field.campaignFieldId} className="flex ">
                    <p className="text-sm text-primary font-interRegular">
                      •
                      <span className="mx-1.5 text-sm text-primary font-interSemiBold">
                        {field.campaignFieldName}:
                      </span>
                      {parseDefaultVal(field.value)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="ml-auto flex-grow-0 h-[20.625rem] w-[20.625rem]">
            <AddressMap
              geoCodingResult={geoCodingResult}
              radius={radiusField?.value}
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex flex-col  w-[65%]">
          <div className="flex items-center mt-8">
            <h3 className="text-primary font-interSemiBold text-sm  mr-1.5">
              Search keywords
            </h3>

            <Tooltip
              Icon={TooltipIcon}
              className="cursor-pointer w-4 h-4 text-gray-500 fill-current self-end"
              bodyText="A search keyword is what people type into search engines. The best keywords are based on relevance, volume of searches, how competitive it is to bid on, alignment to your goals and rank potential. We have selected these keywords for you based on the website you provided"
            />
          </div>
          <p className="font-interRegular text-sm text-primary mt-1.5">
            Your ads will appear for users who are actively searching for these
            topics on search engines.
          </p>
          <p className=" font-interSemiBold text-sm text-primary">
            {formattedKeywords}
          </p>
          {isUpdatingKeywordsFailed && (
            <p className="mt-5 text-sm font-interSemiBold text-colorDelete">
              Failed to update the keywords!
            </p>
          )}
        </div>
        <div className="flex flex-col ml-auto flex-grow-0 w-[20.625rem] mt-8  self-center ">
          <div className="w-full text-center border border-solid border-[#E5E9EB] rounded-md">
            <p className="text-base text-primary py-3 font-interRegular">
              Keyword Count
            </p>
          </div>
          <div className="w-full text-center border rounded-md py-3 border-solid border-[#E5E9EB]">
            <p
              className={` ${
                isIdealKeywordCount ? "text-colorGreen" : "text-colorOrange"
              }  font-interRegular text-[2.5rem]`}
            >
              {numberOfKeywords}
            </p>
            <p className="text-colorOrange font-interRegular text-xs">
              {!isIdealKeywordCount &&
                numberOfKeywords &&
                "Ideal count of keywords should be around 15-20"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignFieldsReadMode;
