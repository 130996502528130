import React, { FC, useMemo } from "react";

import { useAuth } from "../../contexts/auth";
import { NotifierProvider } from "../../contexts/notifier";
import { useToggleSidebar } from "../../contexts/toggleSidebar";

import Navbar from "../../components/navBar";
import Sidebar from "../../components/fixedSideBar";

interface IMainLayout {
  children: React.ReactNode;
  showSidebarLogo?: boolean;
  showSidebar?: boolean;
  showWizard?: boolean;
  showCampaignImage?: boolean;
}

const MainLayout: FC<IMainLayout> = ({
  children,
  showSidebarLogo,
  showSidebar,
  showWizard,
}) => {
  const { user } = useAuth();
  const { isSidebarOpen } = useToggleSidebar();

  const isEmailVerified = useMemo(() => user?.emailVerified, [user]);

  return (
    <div className="flex flex-col  min-h-screen bg-[#f6f6f6]">
      <Navbar isEmailVerified={isEmailVerified} />
      <div className={`flex mt-14 ${isEmailVerified ? "pt-0" : "pt-4"} `}>
        <div className="flex  w-full sm:flex-row flex-col">
          <Sidebar
            showWizard={showWizard}
            showSideBar={showSidebar}
            showSidebarLogo={showSidebarLogo}
          />
          <div
            className={`
            w-full ${
              isSidebarOpen ? " md:ml-[15rem]" : "ml-0"
            }  xs:ml-0  bg-[#f6f6f6] xl:pr-0

          `}
          >
            <NotifierProvider>{children}</NotifierProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
