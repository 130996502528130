import { FC } from "react";

import ContentContainer from "../contentContainer";
import ActivityIndicator from "../activitySpinner";

interface NotifierProps {
  visible: boolean;
  message: string;
  icon?: string;
  isLoading: boolean;
  closeNotifier: () => void;
}

export const Notifier: FC<NotifierProps> = ({
  visible,
  message,
  icon,
  isLoading,
  closeNotifier,
}) => {
  return isLoading ? (
    <ActivityIndicator />
  ) : (
    <ContentContainer
      className={`${visible ? "flex mb-4 px-2 py-3 " : "hidden"}`}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center space-x-4">
          <img className="w-6 h-6" src={icon} alt="notifier__icon" />
          <p className=" font-ManropeBold">{message}</p>
        </div>

        <div className="flex" onClick={closeNotifier}>
          <p>Hide</p>
        </div>
      </div>
    </ContentContainer>
  );
};
