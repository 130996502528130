import React from "react";

interface RoundedButtonProps {
  className?: string;
  onClick?: () => void;
  text: string | JSX.Element;
  isEnabled?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  bgColor?: string;
  textColor?: string;
  borderRadius?: string;
  borderColor?: string;
}

const RoundedButton: React.FC<RoundedButtonProps> = ({
  className,
  onClick,
  text,
  isEnabled = true,
  type,
  bgColor = "bg-[#3077aa]",
  borderRadius = "rounded-[2rem]",
  textColor = "text-white",
  borderColor,
}) => {
  const handleOnClick = !isEnabled ? undefined : onClick;
  return (
    <button
      className={`flex  shadow-md ${
        !isEnabled
          ? "cursor-not-allowed bg-[#EBEBE4]"
          : `cursor-pointer ${bgColor} `
      } ${borderRadius} border ${borderColor} font-interRegular items-center justify-center ${className}`}
      onClick={handleOnClick}
      type={type}
    >
      {typeof text === "string" ? (
        <p className={`${textColor} text-sm  `}>{text}</p>
      ) : (
        text
      )}
    </button>
  );
};

export default RoundedButton;
