import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useAllAdvertisers } from "../../services/advertiser";

import { Selection } from "../../components/select";
import { useToggleSidebar } from "../../contexts/toggleSidebar";

const SidebarLogo = () => {
  const navigateTo = useNavigate();

  const { isSidebarOpen } = useToggleSidebar();

  const { selectedAdvertiser, setSelectedAdvertiser } = useSelectedAdvertiser();

  // const [dataPageNumber, setDataPageNumber] = useState<number>(0);

  const { data: advertisersData, isPending: isAdvertisersPending } =
    useAllAdvertisers({
      page: 0,
      limit: 20,
    });

  const selectionData = advertisersData?.data.map((item: any) => ({
    id: item.id,
    value: item.displayName,
    label: item.displayName,
    photoUrl: item.photoUrl,
  }));

  const selectionValue = useMemo(() => {
    return selectionData?.find(
      (advertiser) => advertiser.id === selectedAdvertiser
    );
  }, [selectionData, selectedAdvertiser]);

  // function handleLoadMore() {
  //   setDataPageNumber((prev) => prev + 1);
  // }

  useEffect(() => {
    if (selectedAdvertiser && !selectionValue && !isAdvertisersPending) {
      localStorage.removeItem("advertiser");
      setSelectedAdvertiser(null);
    }

    // // if advertisers are present but no advertiser is selected, select the first advertiser by default
    if (advertisersData?.data.length !== 0 && !selectedAdvertiser) {
      setSelectedAdvertiser(advertisersData?.data[0].id ?? null);
    }
    //eslint-disable-next-line
  }, [
    selectionValue,
    advertisersData,
    selectedAdvertiser,
    isAdvertisersPending,
  ]);

  const AdvertiserSelector = () => {
    return (
      <Selection
        closeMenuOnSelect={false}
        placeholder={
          selectionValue
            ? selectionValue.label
            : isAdvertisersPending
            ? ""
            : "Switch Advertiser"
        }
        // hasMoreData={advertisersData?.pagination.nextPage ? true : false}
        // handleLoadMore={handleLoadMore}
        data={selectionData}
        value={selectionValue}
        isPending={isAdvertisersPending}
        isMulti={undefined}
        customStyle={{
          selectContainer: {
            display: `${isSidebarOpen ? "flex" : "none !important"}`,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#E5EBEE",
            borderRadius: "0.25rem",
            width: "100%",
            fontSize: 14,
            fontFamily: "InterRegular",
          },
          valueContainer: {
            paddingInline: "0.75rem",
            paddingBlock: "0",
          },
          color: "#d3d3d3",
          optionisSelectedBgColor: "transparent",
          optionisFocusedBgColor: "#d7edff",
          optionisFocusedTextColor: "#0e73f6",
          singleValStyles: {
            backgroundColor: "transparent",
          },
        }}
        onChange={(selectedOption) => {
          setSelectedAdvertiser(selectedOption.id);
        }}
      />
    );
  };

  const NoAdvertisersMsg = () => (
    <p
      className={` ${
        isSidebarOpen ? "block" : "hidden"
      } font-ManropeSemiBold text-center`}
    >
      No available advertiser. Please visit{" "}
      <span
        className=" cursor-pointer font-ManropeExtraBold text-[#5972A5]"
        onClick={() => navigateTo("/advertiser")}
      >
        Advertisers
      </span>{" "}
      page and create one.
    </p>
  );

  const LogoSkeleton = () => (
    <div className="flex justify-center items-center">
      <div className=" animate-pulse h-[5.5rem]  w-[5.5rem] bg-gray-200  rounded-full  "></div>
    </div>
  );

  const AdvertiserLogo = () => (
    <div className=" w-full flex items-center justify-center">
      {selectionValue && selectedAdvertiser ? (
        <img
          src={selectionValue?.photoUrl}
          className=" object-contain h-[7rem] w-[9rem]"
          alt={`${selectionValue.label}.png`}
        />
      ) : (
        <LogoSkeleton />
      )}
    </div>
  );

  return (
    <div className="bg-white flex  w-full xs:mt-20 md:mt-6 justify-center rounded-lg flex-col space-y-5">
      {advertisersData?.data.length === 0 ? (
        <NoAdvertisersMsg />
      ) : (
        <>
          <AdvertiserLogo />
        </>
      )}
      <AdvertiserSelector />
    </div>
  );
};

export default SidebarLogo;
