import React, { useEffect } from "react";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "../../contexts/auth";

import ContentContainer from "../../components/contentContainer";
import TopContent from "../../components/topContent";
import RoundedButton from "../../components/roundedButton";

import { ProfileContent } from "../../interfaces";

interface DisplayProfileProps {
  data: {
    displayName: string;
    email: string;
    phoneNumber: string;
    role: string;
  };
  onClickEdit: (content: ProfileContent) => void;
  isEditUserSuccess: boolean;
}

const DisplayProfile: React.FC<DisplayProfileProps> = ({
  data,
  onClickEdit,
  isEditUserSuccess,
}) => {
  const { isSuccess: isUpdateUserPasswordSuccess, clearAuthStates } = useAuth();

  useEffect(() => {
    if (isUpdateUserPasswordSuccess) {
      toast.success("Password updated successfully");
    }
    setTimeout(() => {
      clearAuthStates();
    }, 1000);
    // eslint-disable-next-line
  }, [isUpdateUserPasswordSuccess]);

  useEffect(() => {
    if (isEditUserSuccess) {
      toast.success("User fields updated successfully!");
    }
  }, [isEditUserSuccess]);

  return (
    <div className="flex flex-col">
      <TopContent title="User Profile" subTitle="" />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      <ContentContainer className="p-8 h-[70vh] max-h-[50rem]">
        <div className="flex ml-auto ">
          <RoundedButton
            onClick={() => onClickEdit("changePassword")}
            type="button"
            className="py-3 px-9 mr-8 max-w-fit max-h-fit ml-auto "
            borderRadius="rounded-md"
            text={
              <p className=" text-sm uppercase text-white inline-flex items-center font-interRegular">
                Change Password
              </p>
            }
          />
          <RoundedButton
            onClick={() => onClickEdit("editProfile")}
            type="button"
            className="py-3 px-9  max-w-fit max-h-fit ml-auto"
            borderRadius="rounded-md"
            text={
              <p className=" text-sm uppercase text-white inline-flex items-center font-interRegular">
                Edit
              </p>
            }
          />
        </div>
        <div className="flex flex-col space-y-5 mt-5">
          <p className="text-sm font-interSemiBold text-primary">
            Name:
            <span className="text-sm ml-1.5 font-interRegular text-primary">
              {data.displayName}
            </span>
          </p>
          <p className="text-sm font-interSemiBold text-primary">
            Email:
            <span className="text-sm ml-1.5 font-interRegular text-primary">
              {data.email}
            </span>
          </p>
          <p className="text-sm font-interSemiBold text-primary">
            Phone:
            <span className="text-sm ml-1.5 font-interRegular text-primary">
              {data.phoneNumber}
            </span>
          </p>
        </div>
      </ContentContainer>
    </div>
  );
};
export default DisplayProfile;
