import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

export function useGeocoding({ address }: { address: string | undefined }) {
  const geocodingApiLoaded = useMapsLibrary("geocoding");
  const [geoCodingService, setGeoCodingService] =
    useState<google.maps.Geocoder>();
  const [geoCodingResult, setGeoCodingResult] =
    useState<google.maps.GeocoderResult>();

  useEffect(() => {
    if (!geocodingApiLoaded) return;
    setGeoCodingService(new window.google.maps.Geocoder());
  }, [geocodingApiLoaded]);

  useEffect(() => {
    if (!geoCodingService || !address) return;

    geoCodingService.geocode({ address }, (results, status) => {
      if (results && status === "OK") {
        setGeoCodingResult(results[0]);
      }
    });
  }, [geoCodingService, address]);

  return {
    geoCodingResult,
  };
}
