import { useNavigate } from "react-router-dom";

import { useWebsiteType } from "../../services/website-types";

import { truncateText } from "../../utils/truncateText";

const WebsiteNameColumnRenderer = ({ params }: { params: any }) => {
  const navigate = useNavigate();

  async function handleViewAssets(id: number) {
    navigate(`/websites/assets/${id}`);
  }

  const { id, shortName, websiteTypeId, numberOfAssets, isEditable, url } =
    params.data;
  const { data } = useWebsiteType(id, websiteTypeId);

  return (
    <div className="flex flex-col">
      <div className="flex">
        <p
          className={`${
            !isEditable ? "text-gray-300" : "text-primary"
          } text-sm font-interSemiBold`}
        >
          {shortName}
        </p>
      </div>

      <div className="flex mt-1">
        <p
          className={`${
            !isEditable ? "text-gray-300" : "text-primary"
          }   text-xs inline-flex font-interRegular capitalize`}
        >
          • Type: <span className="capitalize ml-1">{data?.name}</span>
        </p>
        <p
          className={`${
            !isEditable ? "text-gray-300" : "text-primary"
          } text-xs  inline-flex font-interRegular capitalize items-center ml-3`}
        >
          • Media assets:
          {isEditable ? (
            <span
              onClick={() => handleViewAssets(id)}
              className="text-xs font-interRegular underline text-colorBlue ml-1 cursor-pointer "
            >
              {numberOfAssets}
            </span>
          ) : (
            <span className="text-xs font-interRegular ">0</span>
          )}
        </p>
        <p
          className={`${
            !isEditable ? "text-gray-300" : "text-primary"
          } text-xs  inline-flex font-interRegular items-center ml-3`}
        >
          <span className="capitalize">• Url:</span>

          <span className="text-xs font-interRegular ml-1 ">
            {truncateText(url, 40)}
          </span>
        </p>
      </div>
    </div>
  );
};

export default WebsiteNameColumnRenderer;
