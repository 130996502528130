export function parseStringObject(defaultVal: string | null) {
  if (!defaultVal) return;
  if (defaultVal.startsWith("{") && defaultVal.endsWith("}")) {
    const cleanedInput = defaultVal
      .slice(1, -1)
      .replace(/\\?"/g, "")
      .replace(/\.\s*/g, ". ")
      .split(",");
    return cleanedInput.map((value) => value.trim());
  }
}
