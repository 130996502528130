import { useQuery } from "@tanstack/react-query";
import { request } from "../axios";

interface IBusinessProfileResponse {
  id: number;
  address: string;
  advertiserId: number;
  websiteId: number;
  websiteUrl: string;
  description: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  tiktok: string;
  twitter: string;
  phone: string;
  review: string;
  reviewsFromWeb: string | null;
  createdAt: Date;
  updatedAt: Date;
}

function getBusinessProfile(
  advertiserId: number | null
): Promise<IBusinessProfileResponse[]> {
  return request({
    method: "get",
    url: `google-business-profile/advertiser/${advertiserId}`,
  });
}

export function useAdvertiserBusinessProfile(advertiserId: number | null) {
  return useQuery({
    queryKey: ["advertiserBusinessProfile", advertiserId],
    queryFn: () => getBusinessProfile(advertiserId),
  });
}
