import { ICampaignFieldsResponse } from "../../../../services/industry-organization-campaign-fields";

export const categorizeFields = (
  fields: ICampaignFieldsResponse[] | undefined,
  isGeneralFieldsIncluded: boolean = false
): { [key: string]: ICampaignFieldsResponse[] } => {
  if (!fields) return {};

  return fields.reduce(
    (
      acc: { [key: string]: ICampaignFieldsResponse[] },
      field: ICampaignFieldsResponse
    ) => {
      if (!isGeneralFieldsIncluded) {
        if (!acc[field.categoryType] && field.categoryType !== "GENERAL") {
          acc[field.categoryType] = [];
        }
        if (field.categoryType !== "GENERAL") {
          acc[field.categoryType].push(field);
        }
      } else {
        if (!acc[field.categoryType]) {
          acc[field.categoryType] = [];
        }
        acc[field.categoryType].push(field);
      }
      return acc;
    },
    {}
  );
};
