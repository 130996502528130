import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useAddSite } from "../../contexts/addWebsite";

import SearchBar from "../../components/urlBar";
import { Selection } from "../../components/select";
import Input from "../../components/input/inputWithToggle";
import RoundedButton from "../../components/roundedButton";
import ContentContainer from "../../components/contentContainer";
import TopContent from "../../components/topContent";

import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";

import { useWebsiteTypes } from "../../services/website-types";
import { IWebDetailsRequest } from "../../services/web-details";
import { useAdvertiser } from "../../services/advertiser";

interface TransformedItem {
  label: string;
  value: number;
}

export default function AddWebsite() {
  const navigate = useNavigate();

  const { selectedAdvertiser } = useSelectedAdvertiser();
  const { addNewSite } = useAddSite();

  const { data: selectedAdvertiserData } = useAdvertiser(selectedAdvertiser);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    shortName: string;
    urlBarValue: string;
  }>();

  const formState = watch();

  const [websiteTypeId, setWebsiteTypeId] = useState<number | undefined>(
    undefined
  );
  const [websiteTypeErrMsg, setWebsiteTypeErrMsg] = useState<
    string | undefined
  >(undefined);

  const { data: websiteTypes, isPending: isWebsiteTypesLoading } =
    useWebsiteTypes(selectedAdvertiserData?.industryId, selectedAdvertiser);

  const isAllFieldsFilled =
    formState.urlBarValue !== "" && websiteTypeId !== null;

  const isSelectedAdvertiserFieldsFilled =
    selectedAdvertiserData?.isFieldsFilled;

  const websiteTypesData: TransformedItem[] = websiteTypes?.map(
    (item: any) => ({
      label: item.name,
      value: item.id,
    })
  );

  async function handleSave(formData: any) {
    if (selectedAdvertiser && isAllFieldsFilled) {
      const data: IWebDetailsRequest = {
        url: formData.urlBarValue,
        shortName:
          formData.shortName === "" ? formData.urlBarValue : formData.shortName,
        websiteTypeId,
        advertiserId: selectedAdvertiser,
      };

      addNewSite(data);
      navigate("/websites");
    }
  }

  const onSubmit: SubmitHandler<any> = async (formData) => {
    await handleSave(formData);
  };

  function handleFormSubmit(e: React.FormEvent) {
    if (!websiteTypeId) {
      setWebsiteTypeErrMsg("Type is required");
    }

    handleSubmit(onSubmit)(e);
  }

  function handleEditAdvertiser() {
    navigate(`/advertiser/${selectedAdvertiser}`);
  }

  const [select, setSelect] = useState<{ label: any; value: any }>();

  useEffect(() => {
    if (websiteTypesData) {
      const landingPage = websiteTypesData?.find(
        (d) => d.label === "Landing Page"
      );
      setSelect(landingPage);
      setWebsiteTypeId(landingPage?.value);
    }
    // eslint-disable-next-line
  }, [selectedAdvertiser, websiteTypesData?.length]);

  return (
    <div>
      <TopContent
        title="Add New Website"
        subTitle="Enter the website you'd like to advertise below, and we'll help you connect with the right audience. Let's get started!"
      />
      <ContentContainer>
        <div className=" flex flex-1 flex-col p-12 pb-0 ">
          <div className=" flex flex-1 flex-col space-y-5 justify-between mb-12 items-center sm:items-stretch">
            {selectedAdvertiserData && !isSelectedAdvertiserFieldsFilled ? (
              <>
                <p className="text-sm font-interRegular ">
                  Some fields in the selected advertiser's info are missing. It
                  might be a good idea to double-check and update that to start
                  adding your websites.
                </p>

                <RoundedButton
                  onClick={handleEditAdvertiser}
                  borderRadius="rounded-lg"
                  text="Edit Advertiser Information"
                  className="px-6 py-2.5 text-sm font-interSemiBold  self-center"
                />
              </>
            ) : (
              <>
                {!selectedAdvertiser ? (
                  <div className=" flex flex-1 flex-col  min-h-[36.125rem] max-h-[67rem] pt-[2.6rem] xs:px-2 md:px-6  lg:pl-9 lg:pr-20">
                    <div className="flex flex-1 flex-col w-full space-y-8">
                      {/* <p className="text-sm text-primary font-interRegular">
                        Welcome to your campaign dashboard! 
                      </p> */}
                      <p className="text-sm text-primary font-interRegular">
                        Looks like you're ready to start creating your website
                        awesome! Before we dive in, you’ll need to add an
                        advertiser to get things rolling.
                      </p>
                      <p className="text-sm text-primary font-interRegular">
                        Tap the 'Add Advertiser' button to get started, and then
                        you’ll be all set to create your website!"
                      </p>
                      <div className="mt-10">
                        <RoundedButton
                          text={
                            <p className=" text-sm text-white inline-flex items-center font-interRegular">
                              <EditIcon className="text-white fill-current mr-2 text-2xl " />
                              Add Advertiser
                            </p>
                          }
                          onClick={() => {
                            navigate("/advertiser/addAdvertiser");
                          }}
                          bgColor="bg-[#3077aa]"
                          textColor="text-[#a5aab6]"
                          className="py-2.5 px-4 mx-auto mt-5"
                          borderRadius="rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  selectedAdvertiserData &&
                  isSelectedAdvertiserFieldsFilled && (
                    <div className="flex flex-col space">
                      <form onSubmit={handleFormSubmit} autoComplete="off">
                        <div className="flex  flex-col gap-x-8 space-y-6">
                          <SearchBar
                            label="URL"
                            name="urlBarValue"
                            register={register}
                            pattern={{
                              message:
                                "Make sure url is in correct format and starts with http/s",
                              value:
                                /^(https?:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
                            }}
                            requiredMessage="URL is required"
                            reactHooksFormEnabled={true}
                            urlErrMesg={errors.urlBarValue?.message}
                          />

                          <div className="flex items-center">
                            <div className="flex  flex-col">
                              <label
                                className="font-interRegular text-sm text-secondary mb-1"
                                htmlFor="industryId"
                              >
                                Type
                              </label>
                              <div className="flex items-center">
                                <div className="relative xs:w-[18.75rem] sm:w-[27.5rem]">
                                  <Selection
                                    isPending={isWebsiteTypesLoading}
                                    data={websiteTypesData}
                                    placeholder=""
                                    value={select}
                                    onChange={(
                                      selectedOption: any,
                                      actionMeta: any
                                    ) => {
                                      setSelect(selectedOption);
                                      setWebsiteTypeId(selectedOption.value);
                                      setWebsiteTypeErrMsg(undefined);
                                    }}
                                    closeMenuOnSelect={true}
                                    isMulti={undefined}
                                    customStyle={{
                                      selectContainer: {
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        borderColor: `${
                                          websiteTypeErrMsg
                                            ? "#D34638"
                                            : "#40444f"
                                        }`,
                                        borderRadius: "0.625rem",
                                        width: "100%",
                                        fontSize: 14,
                                        marginTop: "1px",
                                      },
                                      valueContainer: {
                                        paddingInline: "0.75rem",
                                        paddingBlock: "0.43rem",
                                      },
                                      color: "#d3d3d3",
                                      optionisSelectedBgColor: "transparent",
                                      optionisFocusedBgColor: "#335498",
                                      optionisFocusedTextColor: "white",
                                      singleValStyles: {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {websiteTypeErrMsg && (
                              <p className=" text-sm mt-4 ml-2 font-interSemiBold text-colorDelete">
                                {websiteTypeErrMsg}
                              </p>
                            )}
                          </div>

                          <Input
                            type="text"
                            name="shortName"
                            label="Short description"
                            register={register}
                            placeholder="Give a website name for future reference"
                            requiredMessage={undefined}
                            validation={errors.shortName?.message}
                            reactHooksFormEnabled={true}
                            validationClassName="text-red-400 text-sm"
                            className={`${
                              errors.shortName?.message && "border-[#D34638] "
                            }   `}
                          />
                        </div>
                        <div className="flex  pt-10 items-center ">
                          <RoundedButton
                            className="py-2.5 px-4"
                            type="submit"
                            borderRadius="rounded-md"
                            text={
                              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                                <EditIcon className="text-white fill-current mr-2 text-2xl " />
                                {"SAVE"}
                              </p>
                            }
                          />
                          <p
                            onClick={() => navigate(-1)}
                            className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
                          >
                            Cancel
                          </p>
                        </div>
                      </form>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </ContentContainer>
    </div>
  );
}
