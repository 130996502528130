import { createContext, useContext, useEffect, useState } from "react";

import { IDomainData, useDomainStyle } from "../../services/multitenacy-config";

import { parseDomainBeforeVeloxi } from "../../utils/parseDomainBeforeVeloxi";

interface DomainContextProps {
  domainStyleData: IDomainData | undefined;
  isPending: boolean;
  isError: boolean;
  domainName: string | null;
  isDomainStyleBeingFetched: boolean;
}

const DomainStyleContext = createContext<DomainContextProps | undefined>(
  undefined
);

export const useDomain = () => {
  const context = useContext(DomainStyleContext);
  if (!context) {
    throw new Error("useDomain must be used within a DomainProvider");
  }
  return context;
};

export const DomainProvider = ({ children }: { children: React.ReactNode }) => {
  const [domainName, setDomainName] = useState<string | null>(null);
  const {
    data: domainStyleData,
    isPending: isDomainStylePending,
    isFetching: isDomainStyleBeingFetched,
    isError: isDomainStyleFailed,
  } = useDomainStyle(domainName);

  useEffect(() => {
    const hostname = window.location.hostname;
    const tenant = parseDomainBeforeVeloxi(hostname);
    setDomainName(tenant);
    // setDomainName("dev");
  }, []);

  return (
    <DomainStyleContext.Provider
      value={{
        domainName,
        isPending: isDomainStylePending,
        isError: isDomainStyleFailed,
        isDomainStyleBeingFetched,
        domainStyleData,
      }}
    >
      {children}
    </DomainStyleContext.Provider>
  );
};
