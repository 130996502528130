import { SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as AttachFileIcon } from "../../assets/icons/attach-file-icon-mui.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon-mui.svg";

import Tooltip from "../../components/tooltip";

import { isOlderThanSpecifiedMinutes } from "../../utils/isOlderThanSpecifiedMinutes";

const WebsiteActionColumnRenderer = ({
  params,
  setItemIdToDelete,
  setDeletionModal,
}: {
  params: any;
  setItemIdToDelete: React.Dispatch<SetStateAction<number | null>>;
  setDeletionModal: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();

  const { id, isEditable, updatedAt } = params.data;

  async function handleEditAssetsData(id: number) {
    navigate(`/websites/assets/${id}`);
  }
  async function handleEditMetaData(id: number) {
    navigate(`/websites/metadata/${id}`);
  }

  function handleDeletionModal(id: number) {
    setItemIdToDelete(id);
    setDeletionModal(true);
  }

  return (
    <div className="flex">
      <div
        className="cursor-pointer mr-1.5"
        onClick={() => {
          if (isEditable) {
            handleEditAssetsData(params.data.id);
          }
          return;
        }}
      >
        <Tooltip
          Icon={AttachFileIcon}
          bodyText="Media Asset"
          className={`w-6 h-6 text-gray-500
                  ${!isEditable ? "opacity-20" : "opacity-100"}
                  fill-current`}
        />
      </div>
      <div
        className="cursor-pointer"
        onClick={() => {
          if (isEditable) {
            handleEditMetaData(params.data.id);
          }
          return;
        }}
      >
        <Tooltip
          Icon={EditIcon}
          bodyText="Edit Website details"
          className={`w-6 h-6 text-gray-500
                  ${!isEditable ? "opacity-20" : "opacity-100"}
                  fill-current`}
        />
      </div>
      <div
        onClick={() => {
          if (!isOlderThanSpecifiedMinutes(updatedAt) && !isEditable) {
            return;
          }
          handleDeletionModal(id);
        }}
        className="ml-1.5 cursor-pointer"
      >
        {
          <Tooltip
            Icon={DeleteIcon}
            bodyText="Delete"
            className={`w-6 text-gray-500 fill-current h-6
            ${
              !isOlderThanSpecifiedMinutes(updatedAt) && !isEditable
                ? "opacity-20"
                : "opacity-100"
            }`}
          />
        }
      </div>
    </div>
  );
};

export default WebsiteActionColumnRenderer;
