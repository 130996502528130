import { Map } from "@vis.gl/react-google-maps";

import { Circle } from "../../components/googleMap/circle";

const AddressMap = ({
  radius,
  geoCodingResult,
}: {
  radius: string | undefined;
  geoCodingResult: google.maps.GeocoderResult | undefined;
}) => {
  const radiusInt = radius && parseFloat(radius) * 1609.344;
  const lat = geoCodingResult?.geometry.location.lat();
  const long = geoCodingResult?.geometry.location.lng();

  return lat && long && radiusInt ? (
    <div className="h-full w-full">
      <Map
        mapTypeControl={false}
        defaultZoom={10}
        streetViewControl={false}
        defaultCenter={{ lat: lat, lng: long }}
      >
        <Circle
          radius={radiusInt}
          center={{ lat: lat, lng: long }}
          strokeColor={"#0c4cb3"}
          strokeOpacity={1}
          strokeWeight={3}
          fillColor={"#3b82f6"}
          fillOpacity={0.3}
        />
      </Map>
    </div>
  ) : null;
};

export default AddressMap;
