import { useState } from "react";

import { useForm } from "react-hook-form";

import RoundedButton from "../../../../../components/roundedButton";

import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-icon-mui.svg";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/arrow-circle-right-mui.svg";

import {
  ICreativeImageCopySuggestionRequest,
  IUpdateCreativeImageCopySuggestionRequest,
  useCreativeCopySuggestion,
} from "../../../../../services/image-copy/suggestion";
import ActivityIndicator from "../../../../../components/activitySpinner";
import { UseMutateAsyncFunction } from "@tanstack/react-query";

const CopyEdit = ({
  requestedCopyData,
  setCreativeEditMode,
  isUpdatingCopy,
  isFailedToUpdateCopy,
  updateCopyCreative,
}: {
  requestedCopyData: {
    id: number;
    copy: string;
  } | null;
  setCreativeEditMode: React.Dispatch<
    React.SetStateAction<"copyList" | "copyEdit">
  >;
  isUpdatingCopy: boolean;
  isFailedToUpdateCopy: boolean;
  updateCopyCreative: UseMutateAsyncFunction<
    any,
    Error,
    IUpdateCreativeImageCopySuggestionRequest,
    unknown
  >;
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      instructions: "",
    },
  });

  const {
    mutateAsync: generateCopySuggestions,
    data: suggestions,
    isPending: isCopySuggestionsPending,
  } = useCreativeCopySuggestion();

  const [selectedSuggestion, setSelectedSuggestion] = useState<string | null>(
    null
  );

  async function onSubmit(data: any) {
    if (requestedCopyData?.id) {
      const reqData: ICreativeImageCopySuggestionRequest = {
        imageCopyId: requestedCopyData?.id,
        instructions: data.instructions,
      };

      await generateCopySuggestions(reqData);
      reset();
    }
  }

  async function handleUpdateCopy() {
    if (suggestions && selectedSuggestion && !isUpdatingCopy) {
      const reqData: IUpdateCreativeImageCopySuggestionRequest = {
        imageCopyId: suggestions.imageCopyId,
        selectedCopy: selectedSuggestion,
      };
      await updateCopyCreative(reqData);
    }
  }

  return (
    <>
      {isCopySuggestionsPending && <ActivityIndicator />}
      <div className="flex flex-col space-y-10">
        <div className="xs:w-full sm:w-[35rem]">
          <h3 className="text-sm font-interSemiBold text-primary mb-1.5">
            Current Copy
          </h3>
          <p className="text-sm text-primary font-interRegular">
            {requestedCopyData?.copy}
          </p>
        </div>
        <div className="flex flex-col">
          <label className="font-interSemiBold text-sm text-primary mb-1.5">
            Tell Me What You'd Like To Change And I’ll Refresh The Copy
          </label>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex">
              <div className="flex">
                <textarea
                  {...register("instructions", {
                    required: "This field is required.",
                    minLength: {
                      value: 10,
                      message: "This field must be at least 10 characters.",
                    },
                    maxLength: {
                      value: 120,
                      message: "This field cannot exceed 120 characters.",
                    },
                  })}
                  placeholder="Change Instructions"
                  className={`xs:w-full sm:w-[35rem] text-sm text-primary  ${
                    errors.instructions?.message
                      ? "border-[#d34638]"
                      : "border-[#40444f]"
                  } h-[6rem]   hide-scrollbar resize-none  focus:outline-none border-[1px] rounded-[0.625rem] py-3 px-3`}
                />
              </div>
              <div className="flex flex-col">
                {errors.instructions?.message && (
                  <p className="font-interSemiBold mt-2  ml-2 text-colorDelete  text-sm ">
                    {errors.instructions.message}
                  </p>
                )}
                <button
                  type="submit"
                  className="justify-self-end mt-auto w-9 h-9 ml-2 rounded-full"
                >
                  <ArrowRight className="text-gray-600 cursor-pointer  fill-current mr-2 w-9 h-9 " />
                </button>
              </div>
            </div>
          </form>
        </div>
        {suggestions && (
          <div className="flex flex-col xs:w-full sm:w-[35rem]">
            <h3 className="text-sm font-interSemiBold text-primary mb-1.5">
              Suggestions
            </h3>
            {suggestions.suggestedCopies.map((suggestion) => (
              <div key={suggestion} className="flex items-center mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={suggestion}
                    onChange={(e) => setSelectedSuggestion(e.target.value)}
                    className="appearance-none cursor-pointer w-6 h-6 border-4 border-black rounded-full checked:bg-transparent checked:relative checked:after:content-[''] checked:after:w-2.5 checked:after:h-2.5 checked:after:bg-colorBlue checked:after:rounded-full checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:transform checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 focus:outline-none"
                    name="option"
                  />
                  <span className="ml-2 text-sm font-interRegular text-primary">
                    Select new
                  </span>
                </label>

                <p className="inline-block text-sm text-primary ml-10">
                  {suggestion}
                </p>
              </div>
            ))}
          </div>
        )}
        {isFailedToUpdateCopy && (
          <p className="text-sm font-interSemiBold -mb-5 text-colorDelete">
            Failed to update the copy value!
          </p>
        )}
        <div className="flex  mt-10 items-center">
          <RoundedButton
            onClick={handleUpdateCopy}
            isEnabled={!!selectedSuggestion}
            className="py-2.5 px-4"
            type="submit"
            borderRadius="rounded-md"
            text={
              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                <EditIcon className="text-white fill-current mr-2 text-2xl " />
                {isUpdatingCopy ? "Submitting.." : "SUBMIT"}
              </p>
            }
          />
          <p
            onClick={() => setCreativeEditMode("copyList")}
            className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
          >
            Cancel and Keep Current
          </p>
        </div>
      </div>
    </>
  );
};

export default CopyEdit;
