import React, { useEffect, useState } from "react";

import { useDomain } from "../../../../contexts/subDomainData";

import CreativeDisplayMode from "./creativeDisplayMode";
import CreativeEditMode from "./creativeEditMode";

import ContentContainer from "../../../../components/contentContainer";
import TableImageModal from "../../../../components/modal/tableImageModal";
import ActivityIndicator from "../../../../components/activitySpinner";

import { IBannerBearImagesResponse } from "../../../../services/wizard/bannerbear";
import { useBudgetOrganizationChannels } from "../../../../services/advertiser/organization-budget-channel";

interface IAds {
  adsFromEach: IBannerBearImagesResponse | undefined;
}

const Ads: React.FC<IAds> = ({ adsFromEach }) => {
  const { domainStyleData, domainName } = useDomain();

  const {
    data: organizationBudgetChannels,
    isPending: isBudgetChannelPending,
  } = useBudgetOrganizationChannels(domainStyleData?.id ?? null, domainName);

  const formattedSelectionData = organizationBudgetChannels
    ?.map((channel) => {
      if (channel.budgetChannel !== "paidEmail") {
        return {
          value: channel.budgetChannel,
          label: channel.channelName,
        };
      }
      return null;
    })
    .filter((channel) => channel !== null);

  const [adCategoryselection, setAdCategorySelection] = useState<{
    label: string;
    value: "social" | "search" | "display";
  } | null>(null);

  const [previewedImage, setPreviewedImage] = useState<{
    id: number;
    imageUrl: string | null;
  } | null>(null);

  const [modalImage, setModalImage] = useState<string | null>(null);
  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (formattedSelectionData) {
      setAdCategorySelection(formattedSelectionData[2]);
    }
    // eslint-disable-next-line
  }, [formattedSelectionData?.length]);

  useEffect(() => {
    if (adCategoryselection?.value && adsFromEach && formattedSelectionData) {
      const adCategoryKey = adCategoryselection?.value;
      const adCategoryBannerBearMap =
        adCategoryKey === "display"
          ? "displayAds"
          : adCategoryKey === "social"
          ? "socialAds"
          : ("searchAds" as keyof IBannerBearImagesResponse);
      const ad = adsFromEach[adCategoryBannerBearMap];
      const selected = ad[0] ?? ad[1] ?? ad[2];

      setPreviewedImage({
        id: selected?.id,
        imageUrl: selected?.imageUrl,
      });
    }
    // eslint-disable-next-line
  }, [adCategoryselection?.value, formattedSelectionData?.length]);

  return (
    <>
      {modalImage && (
        <TableImageModal
          isOpen={modalImage}
          src={modalImage}
          onClose={() => setModalImage(null)}
        />
      )}

      {isBudgetChannelPending && <ActivityIndicator />}
      <ContentContainer className="p-7">
        {isEditEnabled ? (
          <CreativeEditMode setIsEditEnabled={setIsEditEnabled} />
        ) : (
          <CreativeDisplayMode
            isEditEnabled={isEditEnabled}
            setIsEditEnabled={setIsEditEnabled}
            adCategoryselection={adCategoryselection}
            adsFromEach={adsFromEach}
            formattedSelectionData={formattedSelectionData}
            isBudgetChannelPending={isBudgetChannelPending}
            previewedImage={previewedImage}
            setAdCategorySelection={setAdCategorySelection}
            setModalImage={setModalImage}
            setPreviewedImage={setPreviewedImage}
          />
        )}
      </ContentContainer>
    </>
  );
};

export default Ads;
