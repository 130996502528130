import React from "react";

import Select, {
  components,
  GroupBase,
  OptionProps,
  SingleValueProps,
  StylesConfig,
} from "react-select";

import { COUNTRIES } from "../../constants/countries";
import { flags } from "../../assets/flags";
import { TCountry } from "../../interfaces";

interface CountryCodePickerProps {
  onChange: (selectedOption?: any) => void;
  value?: TCountry;
  customSingleValueStyles?: React.CSSProperties | undefined;
  customDropdownMenuStyles: React.CSSProperties;
  dropdownMenuSpan?: React.CSSProperties;
  selectContainer?: React.CSSProperties | undefined;
}

interface CustomCountrySelectStyleProps {
  selectContainer?: React.CSSProperties | undefined;
  customDropdownMenuStyles?: React.CSSProperties | undefined;
}

const customCountryStyles = (
  customStyle: CustomCountrySelectStyleProps
): StylesConfig<TCountry, boolean, GroupBase<TCountry>> => ({
  indicatorSeparator: () => ({ display: "none" }),
  indicatorsContainer: () => ({ display: "none" }),
  dropdownIndicator: () => ({ display: "none" }),
  control: (styles, { isFocused }) => ({
    ...styles,
    ...customStyle.selectContainer,
    outline: "none",
    fontFamily: "font-ManropeRegular",
    borderWidth: "1px",
    boxShadow: "none",
    ":focus-within": {
      outline: "none",
    },
  }),
  menu: (base, props) => ({
    ...base,
    fontSize: 14,
    ...customStyle.customDropdownMenuStyles,
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: 14,
    paddingBlock: "0",
    fontFamily: "font-ManropeRegular",
  }),
  option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    padding: 10,
    backgroundColor: isSelected
      ? "transparent"
      : isFocused
      ? "#335498"
      : undefined,
    color: isFocused ? "white" : isSelected ? "white" : "black",
  }),
});

export const CountryCodePicker: React.FC<CountryCodePickerProps> = ({
  onChange,
  value,
  customSingleValueStyles,
  customDropdownMenuStyles,
  dropdownMenuSpan,
  selectContainer,
}) => {
  const CustomSingleValue = (
    customStyles: React.CSSProperties | undefined,
    dropdownMenuSpan: React.CSSProperties | undefined
  ) => {
    return (props: SingleValueProps<TCountry>) => (
      <components.SingleValue {...props}>
        <div style={customStyles} className="flex space-x-1 items-center">
          <img
            className="w-8 h-4 mr-3"
            src={flags[props.data.flag]}
            alt="country_code_image"
          />
          <span style={dropdownMenuSpan} className="font-ManropeBold">
            +{props.data.value}
          </span>
        </div>
      </components.SingleValue>
    );
  };

  const CustomOption: React.FC<OptionProps<TCountry>> = (props) => (
    <components.Option {...props}>
      <img
        className="w-8 h-4 mr-3"
        src={flags[props.data.flag]}
        alt="country_code_image"
      />
      {`${props.data.label}`}
      <span className="text-xs text-gray-500 ml-2 font-ManropeSemiBold">
        +{props.data.value}
      </span>
    </components.Option>
  );

  return (
    <Select
      className="w-full h-full"
      options={COUNTRIES}
      styles={customCountryStyles({
        customDropdownMenuStyles,
        selectContainer,
      })}
      components={{
        SingleValue: CustomSingleValue(
          customSingleValueStyles,
          dropdownMenuSpan
        ),
        Option: CustomOption,
      }}
      value={value}
      onChange={onChange}
    />
  );
};
