import React from "react";

import { ICampaignFieldsResponse } from "../../../services/industry-organization-campaign-fields";

import { CampaignFieldData } from "../../../interfaces";

import { parseMultipleSelectObject } from "../../../utils/parseMultiSelectObject";

export const renderConfigFieldComponents = ({
  campaignFields,
  setCampaignFieldVals,
  setInitialCampaignFieldVals,
  setMultiSelectFieldIds,
}: {
  campaignFields: ICampaignFieldsResponse[];
  setCampaignFieldVals: (
    value: React.SetStateAction<CampaignFieldData>
  ) => void;
  setInitialCampaignFieldVals: (
    value: React.SetStateAction<CampaignFieldData>
  ) => void;
  setMultiSelectFieldIds: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        campaignFieldName: string;
        campaignFieldId: number;
      }[]
    >
  >;
}) => {
  const fetchedFieldValues = campaignFields.reduce((acc, field) => {
    const componentFields = (field: ICampaignFieldsResponse) => {
      return {
        id: field.id,
        isRequired: field.isRequired,
        campaignFieldId: field.campaignFieldId,
        value: field.defaultValue,
        label: field.defaultValue,
      };
    };

    if (field.type === "DATE") {
      acc[field.campaignFieldName] = field.defaultValue
        ? componentFields(field)
        : null;
    } else if (field.type === "DROPDOWN") {
      acc[field.campaignFieldName] = field.defaultValue
        ? componentFields(field)
        : null;
    } else if (field.type === "MULTISELECT") {
      setMultiSelectFieldIds((prev) => [
        ...prev,
        {
          id: field.id,
          campaignFieldId: field.campaignFieldId,
          campaignFieldName: field.campaignFieldName,
        },
      ]);
      const parsedValues = field.defaultValue
        ? parseMultipleSelectObject(field.defaultValue).map((val: any) => ({
            id: field.id,
            isRequired: field.isRequired,
            campaignFieldId: field.campaignFieldId,
            value: val,
            label: val,
          }))
        : [];
      acc[field.campaignFieldName] = parsedValues;
    } else {
      acc[field.campaignFieldName] = componentFields(field);
    }
    return acc;
  }, {} as CampaignFieldData);
  setCampaignFieldVals({ ...fetchedFieldValues });
  // setValue({ ...fetchedFieldValues });
  setInitialCampaignFieldVals({ ...fetchedFieldValues });
};
