export const tooltipTexts: { [key: string]: string } = {
  BRAND_VOICE: `
    The brand voice guides the AI in shaping the tone of your ads, ensuring they resonate with your audience. Here’s how it can sound:\n
    Casual/Conversational: Friendly, relaxed, and approachable, as if talking to a friend.\n
    Informal/Light-hearted Professional: Slightly more professional but still relaxed and personable.\n
    Neutral/Professional: Direct, clear, and balanced—ideal for business communications without being overly formal.\n
    Formal/Business Professional: Polite and respectful, with a focus on clear and precise communication.\n
    Highly Formal/Authoritative: Very structured, sophisticated, and respectful, often used in official or legal settings.\n
    `,
};
