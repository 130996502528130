import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";

import { ColDef } from "ag-grid-community";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useAddSite } from "../../contexts/addWebsite";

import WebsiteNameColumnRenderer from "./websiteNameColumnRenderer";
import WebsiteStatusColumnRenderer from "./websiteStatusColumnRenderer";
import WebsiteActionColumnRenderer from "./websiteActionColumnRenderer";

import Modal from "../../components/modal";
import RoundedButton from "../../components/roundedButton";
import TopContent from "../../components/topContent";
import { Selection } from "../../components/select";
import Table from "../../components/table";
import ContentContainer from "../../components/contentContainer";

import { useAdvertiserSites, useRemoveLink } from "../../services/web-details";
import { useAdvertiser } from "../../services/advertiser";

import { isOlderThanSpecifiedMinutes } from "../../utils/isOlderThanSpecifiedMinutes";

const Index = () => {
  const { selectedAdvertiser } = useSelectedAdvertiser();
  const { data: advertiser } = useAdvertiser(selectedAdvertiser);

  const navigate = useNavigate();

  const { isWebsiteError, isWebsitePending, isWebsiteSuccess } = useAddSite();

  const [dataPageNumber, setDataPageNumber] = useState<number>(0);
  const {
    data: usersAllSites,
    // isPending: isUsersAllSitesPending,
    isFetching: isUsersAllSitesFetching,
  } = useAdvertiserSites(selectedAdvertiser, dataPageNumber);
  const {
    mutateAsync: removeLink,
    isError: isRemovingLinkError,
    isSuccess: isRemovingLinkSuccess,
  } = useRemoveLink(selectedAdvertiser);

  const [deletionModal, setDeletionModal] = useState<boolean>(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<number | null>(null);

  const [, setFilterStatus] = useState<string | null>(null);
  const [gridApi, setGridApi] = useState<any>(null);
  const [tableTotalPages, setTableTotalPages] = useState<number>(0);
  const numberOfItemsInEachPage = 10;
  const columnData: ColDef[] = [
    {
      headerName: "NAME",
      headerClass: "custom-table-header-padding",
      sortable: false,
      flex: 3,
      resizable: false,
      cellClass: "align-start",
      cellRenderer: (params: any) => (
        <WebsiteNameColumnRenderer params={params} />
      ),
    },
    {
      headerName: "STATUS",
      field: "status",
      filter: true,
      headerClass: "ag-header-border",
      sortable: false,
      flex: 1,
      resizable: false,
      cellRenderer: (params: any) => (
        <WebsiteStatusColumnRenderer params={params} />
      ),
      valueGetter: (params) => {
        const { isEditable, numberOfAssets, isFieldsFilled, updatedAt } =
          params.data;
        return isEditable && numberOfAssets !== 0 && isFieldsFilled
          ? "Ready"
          : !isEditable && isOlderThanSpecifiedMinutes(updatedAt)
          ? "Error"
          : !isEditable && !isOlderThanSpecifiedMinutes(updatedAt)
          ? "Processing"
          : "Missing";
      },
    },
    {
      headerName: "ACTION",
      sortable: false,
      width: 120,
      resizable: false,
      cellRenderer: (params: any) => (
        <WebsiteActionColumnRenderer
          params={params}
          setDeletionModal={setDeletionModal}
          setItemIdToDelete={setItemIdToDelete}
        />
      ),
    },
  ];

  const isAllMetaResponseReady = usersAllSites?.data?.every(
    (site) => site.isEditable === true
  );

  const notEditableItem = usersAllSites?.data?.find(
    (site) => site.isEditable === false
  );

  const [hasInvalidatedQuery, setHasInvalidatedQuery] =
    useState<boolean>(false);

  async function handleDelete(id: number) {
    await removeLink(id.toString());
    setDeletionModal(false);
  }

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);

  function onDropdownChange(value: string | null) {
    if (gridApi) {
      if (value) {
        gridApi.setFilterModel({
          status: {
            filterType: "text",
            type: "equals",
            filter: value === "Ready" ? "Ready" : value,
          },
        });
      } else {
        gridApi.setFilterModel(null);
      }
    }
  }

  useEffect(() => {
    if (isWebsiteError) {
      toast.error(`An error occured while processing request`);
    } else if (isWebsiteSuccess) {
      toast.success("Site added successfully!");
    }
  }, [isWebsiteError, isWebsiteSuccess]);

  useEffect(() => {
    if (isRemovingLinkSuccess) {
      toast.success("Site deleted successfully!");
    } else if (isRemovingLinkError) {
      toast.error("Failed to remove the site");
    }
  }, [isRemovingLinkSuccess, isRemovingLinkError]);

  useEffect(() => {
    if (isWebsitePending) {
      const timeoutId = setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["advertiserSites", selectedAdvertiser],
        });
      }, 2500);

      return () => clearTimeout(timeoutId);
    }
  }, [isWebsitePending, selectedAdvertiser]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isAllMetaResponseReady) {
      queryClient.cancelQueries({
        queryKey: ["addedLink"],
      });
    }

    if (
      !isAllMetaResponseReady &&
      !isOlderThanSpecifiedMinutes(notEditableItem?.updatedAt)
    ) {
      intervalId = setInterval(() => {
        setHasInvalidatedQuery(true);
        queryClient.invalidateQueries({
          queryKey: ["advertiserSites", selectedAdvertiser],
        });
      }, 2000);
    } else {
      setHasInvalidatedQuery(false);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    //eslint-disable-next-line
  }, [isAllMetaResponseReady, selectedAdvertiser]);

  useEffect(() => {
    if (
      isUsersAllSitesFetching &&
      gridApi &&
      !isWebsitePending &&
      !hasInvalidatedQuery
    ) {
      gridApi.showLoadingOverlay();
    } else if (gridApi) {
      gridApi.hideOverlay();
    }
    // eslint-disable-next-line
  }, [gridApi, isUsersAllSitesFetching, isWebsitePending]);

  useEffect(() => {
    if (gridApi && usersAllSites?.data) {
      const totalPages = usersAllSites.pagination.totalPages;
      setTableTotalPages(totalPages);
    }
    // eslint-disable-next-line
  }, [
    gridApi,
    usersAllSites?.data.length,
    usersAllSites?.data,
    selectedAdvertiser,
  ]);

  return (
    <div>
      <TopContent
        title="Websites"
        subTitle={`View of all your websites for ${advertiser?.displayName}`}
      />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      {deletionModal && (
        <Modal
          setIsModalOpen={setDeletionModal}
          modalBodyContent={
            <div className="flex flex-col p-1">
              <p className="font-ManropeSemiBold">
                Are you sure you want to delete this website ?
              </p>
              <div className="flex mt-5 justify-center">
                <RoundedButton
                  text="yes"
                  className="py-2 px-6 ManropeRegular mr-2"
                  onClick={() => {
                    if (itemIdToDelete !== null) {
                      handleDelete(itemIdToDelete);
                      setItemIdToDelete(null);
                    }
                  }}
                />
                <RoundedButton
                  text="no"
                  className="py-2 px-6 font-ManropeRegular bg-gray-300"
                  onClick={() => setDeletionModal(false)}
                />
              </div>
            </div>
          }
        />
      )}
      <ContentContainer>
        <>
          <div className="flex  my-[1px] items-center ">
            <div className="py-2 flex items-center">
              <p className="mx-5 font-interSemiBold text-sm text-primary">
                Filter by:
              </p>
              <Selection
                data={[
                  {
                    label: "all",
                    value: null,
                  },
                  {
                    label: "ready",
                    value: "Ready",
                  },
                  {
                    label: "missing",
                    value: "Missing",
                  },
                  {
                    label: "error",
                    value: "Error",
                  },
                  {
                    label: "processing",
                    value: "Processing",
                  },
                ]}
                isMulti={undefined}
                closeMenuOnSelect={true}
                placeholder={"Status"}
                customStyle={{
                  selectContainer: {
                    marginBlock: "-2px",
                    paddingInline: 7,
                    fontSize: 14,
                    fontFamily: "InterRegular",
                    paddingBlock: 0,
                    borderRadius: "7px",
                    width: "15rem",
                    marginRight: ".7rem",
                  },
                  color: "#d3d3d3",
                  optionisSelectedBgColor: "transparent",
                  optionisFocusedBgColor: "#d7edff",
                  optionisFocusedTextColor: "#0e73f6",
                  singleValStyles: {
                    backgroundColor: "transparent",
                  },
                  valueContainer: {
                    fontFamily: "InterRegular",
                  },
                }}
                onChange={(selectedOption: any, actionMeta: any) => {
                  const value = selectedOption ? selectedOption.value : null;
                  setFilterStatus(value);
                  onDropdownChange(value);
                }}
              />
            </div>
            <div className="max-w-fit flex items-center  ml-auto">
              <RoundedButton
                onClick={() => navigate("/websites/addWebsite")}
                text={
                  <p className=" text-sm text-white inline-flex items-center font-interRegular">
                    <span className="text-2xl   mr-2 text-white">+</span>
                    Add Website
                  </p>
                }
                bgColor="bg-[#3077aa]"
                borderRadius="rounded-lg"
                textColor="text-[#a5aab6]"
                className=" ml-auto px-6 py-1 text-sm font-interSemiBold mr-1"
              />
            </div>
          </div>
          <Table
            gridApi={gridApi}
            setDataPageNumber={setDataPageNumber}
            tableTotalPages={tableTotalPages}
            tableId="usersSitesGrid"
            tableStyle={{
              minHeight: "200px",
              maxHeight: "1149px",
            }}
            rowHeight={80}
            paginationPageSize={numberOfItemsInEachPage}
            onGridReady={onGridReady}
            columnData={columnData}
            assetRowData={usersAllSites?.data ?? []}
          />
        </>
      </ContentContainer>
    </div>
  );
};

export default Index;
