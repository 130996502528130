import { forwardRef } from "react";

const FileUpload = forwardRef<
  HTMLDivElement,
  {
    file: FormData | FormData[] | null;
    dragging: boolean;
    setDragging: React.Dispatch<React.SetStateAction<boolean>>;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    handleDrop: (e: React.DragEvent<HTMLDivElement>) => void;
    mainText?: string;
    subText?: string;
    defaultHeight?: string;
    renderedOnFileSelection: React.ReactNode;
  }
>(
  (
    {
      file,
      dragging,
      setDragging,
      handleChange,
      handleDrop,
      mainText,
      subText,
      defaultHeight,
      renderedOnFileSelection,
    },
    ref
  ) => {
    const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragging(true);
      }
    };

    const isFileArr = Array.isArray(file);

    return (
      <div
        className={`flex items-center justify-center ${
          dragging ? "bg-gray-100" : "bg-white"
        }`}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div
          className={`flex  flex-col w-full  ${
            defaultHeight ? defaultHeight : "h-[25rem]"
          }`}
        >
          {(isFileArr && file.length > 0) || (!isFileArr && file) ? (
            <>{renderedOnFileSelection}</>
          ) : (
            <>
              <label
                htmlFor="dropzone-file"
                className={`flex  flex-col h-full items-center justify-center  border-2 ${
                  dragging ? "border-green-500" : "border-[#40444f]"
                } border-dashed rounded-lg cursor-pointer ${
                  dragging ? "bg-gray-100" : "bg-white"
                }`}
              >
                <svg
                  className="w-8 h-8 mb-4 text-[#547a7a]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-primary font-interRegular">
                  <span className="font-interSemiBold">{mainText}</span>
                </p>
                <p className="text-sm  font-interRegular text-primary ">
                  {subText}
                </p>
              </label>
            </>
          )}
        </div>
        <input
          multiple={isFileArr ? true : false}
          onChange={handleChange}
          id="dropzone-file"
          type="file"
          className="hidden"
        />
      </div>
    );
  }
);
export default FileUpload;
