import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ColDef } from "ag-grid-community";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useCampaign } from "../../contexts/campaign";
import { useStep } from "../../contexts/stepper";

import RoundedButton from "../../components/roundedButton";
import Modal from "../../components/modal";
import TopContent from "../../components/topContent";
import ContentContainer from "../../components/contentContainer";
import Table from "../../components/table";
import { Selection } from "../../components/select";
import Tooltip from "../../components/tooltip";
import ActivitySpinnerNoBg from "../../components/activitySpinner/withNoBgEffect";
import ActivityIndicator from "../../components/activitySpinner";

import { ReactComponent as PdfIcon } from "../../assets/icons/pdf-icon-mui.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon-mui.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon-mui.svg";

import { useAllCampaigns, useRemoveCampaign } from "../../services/campaigns";
import { useAllAdvertisers } from "../../services/advertiser";
import { useKeywords } from "../../services/wizard/campaign";

const Index = () => {
  const navigate = useNavigate();
  const { selectedAdvertiser } = useSelectedAdvertiser();

  const { setStep } = useStep();
  const { campaignId, setCampaignId } = useCampaign();

  const [filterStatus, setFilterStatus] = useState<string | null>(null);

  const [deletionModal, setDeletionModal] = useState<boolean>(false);

  const [itemIdToDelete, setItemIdToDelete] = useState<number | undefined>(
    undefined
  );

  const { data: allAdvertisers } = useAllAdvertisers({
    page: 0,
    limit: 1,
  });

  const [dataPageNumber, setDataPageNumber] = useState<number>(0);
  const numberOfItemsInEachPage = 10;

  const {
    data: campaignsList,
    isPending: isLoadingCampaignList,
    isFetching: isCampaignListFetching,
  } = useAllCampaigns(selectedAdvertiser, {
    limit: numberOfItemsInEachPage,
    page: dataPageNumber,
  });

  const {
    isSuccess: isCampaignRemoved,
    isError: isCampaignFailedToRemove,
    mutateAsync: removeCampaign,
  } = useRemoveCampaign(selectedAdvertiser);

  const { data: keywordsData } = useKeywords(campaignId);
  let formattedKeywords = "";

  if (keywordsData && keywordsData[0]?.keywords) {
    formattedKeywords = keywordsData[0].keywords
      .map((keyword) => `• ${keyword} `)
      .join(" ");

    if (formattedKeywords.length > 500) {
      formattedKeywords = formattedKeywords.slice(0, 500 - 3).trim() + "...";
    }
  }

  const [isPDFPending] = useState<boolean | undefined>(undefined);

  async function handleDelete(id: number) {
    await removeCampaign(id);
    setDeletionModal(false);
  }

  function handleDeletionModal(id: number) {
    setItemIdToDelete(id);
    setDeletionModal(true);
  }

  const [gridApi, setGridApi] = useState<any>(null);
  const [tableTotalPages, setTableTotalPages] = useState<number>(0);
  const columnData: ColDef[] = [
    {
      headerName: "NAME",
      cellClass: "align-start",
      headerClass: "custom-table-header-padding",
      sortable: false,
      flex: 3,
      cellRenderer: (params: any) => {
        const { name, goal, budget } = params.data;
        return (
          <div className="flex flex-col">
            <div className="flex">
              <p className="text-primary text-sm font-interSemiBold">{name}</p>
            </div>

            <div className="flex mt-1">
              <p className="text-primary  text-xs inline-flex font-interRegular">
                • Goal:
                <span className="capitalize font-interRegular">
                  {goal.toLowerCase()}
                </span>
              </p>
              <p className="text-primary text-xs inline-flex ml-2 font-interRegular">
                • 30 day cost: ${budget}
              </p>
            </div>
          </div>
        );
      },
      resizable: false,
    },
    {
      headerName: "STATUS",
      field: "status",
      filter: true,
      headerClass: "ag-header-border",
      sortable: false,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "ACTION",
      sortable: false,
      // flex: 1,
      width: 120,
      cellRenderer: (params: any) => {
        return (
          <div className="flex">
            <div
              className="cursor-pointer"
              onClick={() => {
                setCampaignId(params.data.id);
                setStep(1);
                navigate("/campaigns/wizard");
              }}
            >
              {
                <Tooltip
                  Icon={EditIcon}
                  bodyText="Edit"
                  className="w-6 h-6 text-gray-500 fill-current"
                />
              }
            </div>
            <div
              className="ml-1.5 cursor-pointer"
              onClick={() => {
                setCampaignId(params.data.id);
                setStep(4);
                navigate("/campaigns/wizard");
              }}
            >
              {
                <Tooltip
                  Icon={PdfIcon}
                  bodyText="Download PDF"
                  className="w-6 h-6 text-gray-500 fill-current"
                />
              }
            </div>
            <div
              onClick={() => handleDeletionModal(params.data.id)}
              className="ml-1.5 cursor-pointer"
            >
              {
                <Tooltip
                  Icon={DeleteIcon}
                  bodyText="Delete"
                  className="w-6 h-6 text-gray-500 fill-current"
                />
              }
            </div>
          </div>
        );
      },
      resizable: false,
    },
  ];

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);

  function onDropdownChange(value: string | null) {
    if (gridApi) {
      if (value === "active" || value === "draft") {
        gridApi.setFilterModel({
          status: {
            filterType: "text",
            type: "equals",
            filter: value,
          },
        });
      } else {
        gridApi.setFilterModel(null);
      }
    }
  }

  useEffect(() => {
    if (isCampaignRemoved) {
      toast.success("Campaign deleted successfully!");
    } else if (isCampaignFailedToRemove) {
      toast.error("Failed to remove the campaign");
    }
  }, [isCampaignRemoved, isCampaignFailedToRemove]);

  useEffect(() => {
    if (gridApi && campaignsList?.data && campaignsList.data?.length > 0) {
      const totalPages = campaignsList.pagination.totalPages;
      setTableTotalPages(totalPages);
    }
    // eslint-disable-next-line
  }, [gridApi, campaignsList?.data.length, filterStatus]);

  // console.log(campaignsList?.pagination);

  useEffect(() => {
    if (isCampaignListFetching && gridApi) {
      gridApi.showLoadingOverlay();
    } else if (gridApi) {
      gridApi.hideOverlay();
    }
  }, [isCampaignListFetching, gridApi]);

  return (
    <div className="w-full flex flex-col">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      {deletionModal && (
        <Modal
          setIsModalOpen={setDeletionModal}
          modalBodyContent={
            <div className="flex flex-col p-1">
              <p className="font-ManropeSemiBold">
                Are you sure you want to delete this campaign ?
              </p>
              <div className="flex mt-5 justify-center">
                <RoundedButton
                  text="yes"
                  className="py-2 px-6 ManropeRegular mr-2"
                  onClick={() => {
                    if (itemIdToDelete !== undefined) {
                      handleDelete(itemIdToDelete);
                      setItemIdToDelete(undefined);
                    }
                  }}
                />
                <RoundedButton
                  text="no"
                  className="py-2 px-6 font-ManropeRegular bg-gray-300"
                  onClick={() => setDeletionModal(false)}
                />
              </div>
            </div>
          }
        />
      )}

      <TopContent
        title="Campaign Overview"
        subTitle="Manage and track all advertising campaigns"
      />
      {isPDFPending && <ActivityIndicator />}
      {isLoadingCampaignList && isCampaignListFetching ? (
        <ActivitySpinnerNoBg />
      ) : (
        <>
          <div>
            {!selectedAdvertiser && allAdvertisers?.data?.length === 0 ? (
              <ContentContainer>
                <div className=" flex flex-1 flex-col  min-h-[36.125rem] max-h-[67rem] pt-[5.6rem] xs:px-2 md:px-6  lg:pl-9 lg:pr-20">
                  <div className="flex flex-1 flex-col w-full space-y-8">
                    <p className="text-sm text-primary font-interRegular">
                      Welcome to your campaign dashboard! 
                    </p>
                    <p className="text-sm text-primary font-interRegular">
                      Looks like you're ready to start creating your
                      campaign—awesome! Before we dive in, you’ll need to add an
                      advertiser to get things rolling.
                    </p>
                    <p className="text-sm text-primary font-interRegular">
                      Tap the 'Add Advertiser' button to get started, and then
                      you’ll be all set to create your campaign!"
                    </p>
                    <div className="mt-10">
                      <RoundedButton
                        text={
                          <p className=" text-sm text-white inline-flex items-center font-interRegular">
                            <EditIcon className="text-white fill-current mr-2 text-2xl " />
                            Advertiser
                          </p>
                        }
                        onClick={() => {
                          navigate("/advertiser/addAdvertiser");
                        }}
                        bgColor="bg-[#3077aa]"
                        textColor="text-[#a5aab6]"
                        className="py-2.5 px-4 mx-auto mt-5"
                        borderRadius="rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </ContentContainer>
            ) : !isCampaignListFetching &&
              campaignsList &&
              campaignsList.data?.length === 0 ? (
              <ContentContainer>
                <div className=" flex flex-1 flex-col  min-h-[36.125rem] max-h-[67rem] pt-[5.6rem] xs:px-2 md:px-6  lg:pl-9 lg:pr-20">
                  <div className="flex flex-1 flex-col w-full space-y-8">
                    <p className="text-sm text-primary font-interRegular">
                      Welcome to your campaign overview!{" "}
                    </p>
                    <p className="text-sm text-primary font-interRegular">
                      It’s a bit quiet here right now, but that just means
                      you’ve got a blank canvas ready to bring your advertising
                      dreams to life. Whether you're looking to launch a
                      brand-new campaign, experiment with fresh ideas, or reach
                      new audiences, this is where the magic happens.{" "}
                    </p>
                    <p className="text-sm text-primary font-interRegular">
                      Ready to kick things off? Tap the “+Campaign” button to
                      get started. With just a few clicks, you'll be well on
                      your way to creating a campaign that captivates, engages,
                      and delivers real results. Let's turn your vision into
                      action and watch your brand shine brighter than ever
                      before
                    </p>
                    <div className="mt-10">
                      <RoundedButton
                        text={
                          <p className=" text-sm text-white inline-flex items-center font-interRegular">
                            <span className="text-2xl   mr-2 text-white">
                              +
                            </span>
                            Campaign
                          </p>
                        }
                        onClick={() => {
                          navigate("/campaigns/wizard");
                        }}
                        bgColor="bg-[#3077aa]"
                        borderRadius="rounded-lg"
                        textColor="text-[#a5aab6]"
                        className=" mx-auto px-6 py-1 text-sm font-interSemiBold"
                      />
                    </div>
                  </div>
                </div>
              </ContentContainer>
            ) : (
              allAdvertisers?.data?.length !== 0 &&
              selectedAdvertiser &&
              campaignsList &&
              campaignsList?.data.length > 0 && (
                <ContentContainer>
                  <div className="flex  my-[1px] items-center ">
                    <div className="py-2 flex items-center ">
                      <p className="mx-5 font-interSemiBold text-sm text-primary">
                        Filter by:{" "}
                      </p>
                      <Selection
                        data={[
                          {
                            label: "all",
                            value: null,
                          },
                          {
                            label: "draft",
                            value: "draft",
                          },
                          {
                            label: "active",
                            value: "active",
                          },
                        ]}
                        isMulti={undefined}
                        closeMenuOnSelect={true}
                        placeholder={"Status"}
                        customStyle={{
                          selectContainer: {
                            marginBlock: "-2px",
                            paddingInline: 7,
                            fontSize: 14,
                            fontFamily: "InterRegular",
                            paddingBlock: 0,
                            borderRadius: "7px",
                            width: "15rem",
                            marginRight: ".7rem",
                          },
                          color: "#d3d3d3",
                          optionisSelectedBgColor: "transparent",
                          optionisFocusedBgColor: "#d7edff",
                          optionisFocusedTextColor: "#0e73f6",
                          singleValStyles: {
                            backgroundColor: "transparent",
                          },
                          valueContainer: {
                            fontFamily: "InterRegular",
                          },
                        }}
                        onChange={(selectedOption: any, actionMeta: any) => {
                          const value = selectedOption
                            ? selectedOption.value
                            : null;
                          setFilterStatus(value);
                          onDropdownChange(value);
                        }}
                      />
                    </div>
                    <div className="max-w-fit flex items-center  ml-auto">
                      {/* {assetErrorMessage && (
                  <p className="text-red-400 font-ManropeSemiBold  text-sm mr-5">
                    {assetErrorMessage}
                  </p>
                )} */}
                      <RoundedButton
                        onClick={() => navigate("/campaigns/wizard")}
                        text={
                          <p className=" text-sm text-white inline-flex items-center font-interRegular">
                            <span className="text-2xl  mr-2 text-white">+</span>
                            Add Campaign
                          </p>
                        }
                        bgColor="bg-[#3077aa]"
                        borderRadius="rounded-lg"
                        textColor="text-[#a5aab6]"
                        className=" ml-auto px-6 py-1 mr-1 text-sm font-interSemiBold"
                      />
                    </div>
                  </div>

                  <Table
                    setDataPageNumber={setDataPageNumber}
                    gridApi={gridApi}
                    tableTotalPages={tableTotalPages}
                    tableId="campaignGrid"
                    tableStyle={{
                      minHeight: "200px",
                      maxHeight: "1149px",
                    }}
                    rowHeight={80}
                    paginationPageSize={numberOfItemsInEachPage}
                    onGridReady={onGridReady}
                    columnData={columnData}
                    assetRowData={campaignsList?.data ?? []}
                  />
                </ContentContainer>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Index;
