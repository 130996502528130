import React, { ReactNode, useContext, useState } from "react";

interface IToggleSidebar {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}

interface ToggleSidebarProps {
  children: ReactNode;
}

const ToggleSidebarContext = React.createContext({} as IToggleSidebar);

export const ToggleSidebarProvider: React.FC<ToggleSidebarProps> = ({
  children,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  return (
    <ToggleSidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
      {children}
    </ToggleSidebarContext.Provider>
  );
};

export function useToggleSidebar() {
  const context = useContext(ToggleSidebarContext);
  if (context === undefined) {
    throw new Error(
      "useToggleSidebar must be used within a ToggleSidebarProvider"
    );
  }
  return context;
}
