import { useNavigate } from "react-router-dom";

import { useDomain } from "../../contexts/subDomainData";

import SparkLogo from "../../assets/icons/spark-logo-white-text.png";

interface INavBarProps {
  isEmailVerified?: boolean;
  customNavLinkContainer?: React.ReactNode;
  isDefaultNavLinksEnabled?: boolean;
  isProfileNavEnabled?: boolean;
}

const Navbar: React.FC<INavBarProps> = ({
  isEmailVerified,
  customNavLinkContainer,
}) => {
  const navigate = useNavigate();

  const { domainStyleData } = useDomain();

  const AppNameLogo = () => {
    const onClickLogo = () => navigate("/");
    return (
      <div
        className=" mr-auto  md:translate-x-0 xs:translate-x-0  flex items-center cursor-pointer"
        onClick={onClickLogo}
      >
        <img
          src={domainStyleData?.logoUrl ?? SparkLogo}
          alt="veloxi-ai-logo.png"
          // className="w-30 h-12 object-contain"
          className="w-full h-full object-contain"
        />
      </div>
    );
  };

  const NavRightSide = () => (
    <div className="flex ">
      {customNavLinkContainer ? customNavLinkContainer : null}
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: `${domainStyleData?.themeColor ?? "#0e086a"}`,
      }}
      className={`flex items-center justify-center w-full h-14 xs:pl-14  pr-8 fixed z-50 ${
        isEmailVerified ? "mt-0" : "mt-8"
      }`}
      //
    >
      <div className="flex w-full">
        <AppNameLogo />
        <NavRightSide />
      </div>
    </div>
  );
};

export default Navbar;
