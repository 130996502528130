import MainLayout from "../../layouts/main";
import SingleAdvertiser from "../../containers/advertisers/editAdvertiser";

const Advertiser = () => {
  return (
    <MainLayout showSidebarLogo showSidebar>
      <SingleAdvertiser />
    </MainLayout>
  );
};

export default Advertiser;
