import { useQuery } from "@tanstack/react-query";
import { request } from "../axios";

export interface ICreativeImageCopyResponse {
  id: number;
  campaignId: number;
  channel: "search" | "social" | "display";
  type: string;
  copy: string;
  createdAt: Date;
  updatedAt: Date;
}

function getImageCopy(
  campaignId: number | undefined
): Promise<ICreativeImageCopyResponse[]> {
  return request({
    method: "get",
    url: `image-copy/campaign/${campaignId}`,
  });
}

export function useCreativeImageCopy(campaignId: number | undefined) {
  return useQuery({
    queryKey: ["creativeImageCopy", campaignId],
    queryFn: () => getImageCopy(campaignId),
    enabled: !!campaignId,
  });
}
