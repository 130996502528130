import { ITable } from "../../interfaces";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import ReactPaginate from "react-paginate";

const Table: React.FC<ITable> = ({
  tableId,
  tableStyle,
  tableTheme,
  gridApi,
  gridRef,
  assetRowData,
  columnData,
  onGridReady,
  onCellValueChanged,
  onRowClicked,
  rowHeight,
  pagination,
  paginationPageSize,
  tableTotalPages,
  setDataPageNumber,
}) => {
  const handlePageClick = (event: any) => {
    const selectedPage = event.selected;
    setDataPageNumber && setDataPageNumber(selectedPage);
    if (gridRef) {
      gridRef.current.paginationGoToPage(selectedPage);
    } else {
      gridApi.paginationGoToPage(selectedPage);
    }
  };

  return (
    <div>
      <div
        id={tableId}
        style={
          tableStyle ?? {
            minHeight: "200px",
            maxHeight: "1149px",
          }
        }
        className={tableTheme ?? "ag-theme-quartz "}
      >
        <AgGridReact
          ref={gridRef}
          rowData={assetRowData}
          popupParent={document.body}
          onCellValueChanged={onCellValueChanged}
          onGridReady={onGridReady}
          suppressRowHoverHighlight={true}
          rowSelection={"multiple"}
          suppressRowClickSelection={true}
          suppressPaginationPanel={true}
          columnDefs={columnData}
          rowHeight={rowHeight ?? 70}
          pagination={pagination ?? true}
          paginationPageSize={paginationPageSize ?? 10}
          paginationPageSizeSelector={false}
          onRowClicked={onRowClicked}
          domLayout="autoHeight"
        />
      </div>
      <div className="w-full h-[3.125rem]">
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={2}
          pageCount={tableTotalPages}
          previousLabel="< previous"
          containerClassName="pagination"
          activeClassName="active"
          disabledClassName="disabled"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      </div>
    </div>
  );
};

export default Table;

// const onGridReady = useCallback((params: any) => {
//     setTimeout(() => {
//       params.api.forEachNode((node: any) => {
//         if (node.data.is_excluded) {
//           node.setSelected(true);
//         }
//       });
//     });
//   }, []);

//   cellRenderer: (params: any) => {
//     return (
//       <input
//         type="checkbox"
//         onClick={(e: any) => {
//           e.stopPropagation();
//           const isSelected = e.target.checked;
//           params.node.setSelected(isSelected);
//           params.data.is_excluded = isSelected;
//         }}
//         checked={params.value}
//       />
//     );
//   },
