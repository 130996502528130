import React, { useEffect, useState } from "react";

import { useSelectedAdvertiser } from "../../../../contexts/selectedAdvertiser";
import { useCampaign } from "../../../../contexts/campaign";
import { useStep } from "../../../../contexts/stepper";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FieldErrors, useForm } from "react-hook-form";

import CampaignFieldsRenderer from "./campaignFieldsRenderer";
import CampaignFieldsReadMode from "./campaignFieldsReadMode";

import ContentContainer from "../../../../components/contentContainer";
import RoundedButton from "../../../../components/roundedButton";
import Tooltip from "../../../../components/tooltip";

import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon-mui.svg";
import { ReactComponent as TooltipIcon } from "../../../../assets/icons/tooltip-icon-mui.svg";

import {
  useKeywords,
  useUpdateKeywords,
} from "../../../../services/wizard/campaign";
import { useCampaignFields } from "../../../../services/industry-organization-campaign-fields";
import {
  useSingleCampaignDetails,
  useUpdateCampaignFieldsValues,
} from "../../../../services/campaigns";

import { CampaignFieldData } from "../../../../interfaces";

const Targeting = ({
  multiSelectFieldIds,
  campaignFieldVals,
  setCampaignFieldVals,
  initialCampaignFieldVals,
  setInitialCampaignFieldVals,
  handleCampaignNext,
  isAllConfigFieldsFilled,
  textArea,
}: {
  multiSelectFieldIds: {
    id: number;
    campaignFieldName: string;
    campaignFieldId: number;
  }[];
  campaignFieldVals: CampaignFieldData;
  setCampaignFieldVals: React.Dispatch<React.SetStateAction<CampaignFieldData>>;
  initialCampaignFieldVals: CampaignFieldData;
  setInitialCampaignFieldVals: React.Dispatch<
    React.SetStateAction<CampaignFieldData>
  >;
  handleCampaignNext(): Promise<void>;
  isAllConfigFieldsFilled: boolean;
  textArea: string | null;
}) => {
  const { selectedAdvertiser } = useSelectedAdvertiser();
  const { campaignId } = useCampaign();
  const { setStep } = useStep();

  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);

  const {
    mutateAsync: updateKeywords,
    isPending: isKeywordsUpdatingNow,
    isError: isUpdatingKeywordsFailed,
  } = useUpdateKeywords(campaignId);
  const { data: keywordsData } = useKeywords(campaignId);
  const { data: campaignFields } = useCampaignFields(
    selectedAdvertiser,
    campaignId
  );
  const { data: campaignDetailsData } = useSingleCampaignDetails(
    campaignId,
    selectedAdvertiser
  );

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
    getValues,
    formState,
    reset,
    setFocus,
  } = useForm({
    defaultValues: campaignFieldVals,
  });
  const campaignFieldsFormData = watch();

  const {
    handleSubmit: handleSubmitKeywords,
    setValue: setValueKeywords,
    watch: watchKeywords,
    register: registerKeywords,
    formState: formStateKeywords,
  } = useForm({
    defaultValues: {
      keywords: "",
    },
  });

  const keywordsFormData = watchKeywords("keywords");
  let formattedKeywords = "";
  let numberOfKeywords: number | null = null;

  if (keywordsData && keywordsData[0]?.keywords) {
    numberOfKeywords = keywordsData[0].keywords.length;

    formattedKeywords = keywordsData[0].keywords
      .map((keyword) => `• ${keyword} `)
      .join(" ");

    if (formattedKeywords.length > 500) {
      formattedKeywords = formattedKeywords.slice(0, 500 - 3).trim() + "...";
    }
  }

  function onSubmit(data: any) {
    handleSave();
    reset({ ...campaignFieldVals });
    if (
      formState.errors &&
      formState.errors[Object.keys(formState.errors)[0]]
    ) {
      setFocus(Object.keys(formState.errors)[0]);
    }
    setInitialCampaignFieldVals({ ...campaignFieldVals });
  }

  const onSubmitKeywords = (data: any) => {};

  const {
    mutateAsync: updateCampaignFieldsValues,
    isError: cannotUpdateCampaignFields,
    isSuccess: isFieldsUpdateSuccess,
  } = useUpdateCampaignFieldsValues({
    advertiserId: selectedAdvertiser,
    campaignId,
  });

  async function updateCampaignFields() {
    const reqDataFormat = Object.entries(campaignFieldsFormData).map(
      ([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length === 1 && value[0].value.length === 0) {
            const { id, campaignFieldId, value: fieldValue } = value[0];

            return {
              id,
              campaignFieldId,
              value: fieldValue,
            };
          }
          const accumulatedValues = value.reduce((acc, cFieldObject) => {
            const { id, campaignFieldId, value } = cFieldObject;
            if (!acc[campaignFieldId]) {
              acc[campaignFieldId] = {
                id,
                campaignFieldId,
                value: new Set(),
              };
            }
            acc[campaignFieldId].value.add(value);
            return acc;
          }, {});

          const arrayObj = Object.values(accumulatedValues).map((obj: any) => ({
            id: obj.id,
            campaignFieldId: obj.campaignFieldId,
            value: `{"${Array.from(obj.value).join('","')}"}`,
          }));
          return arrayObj[0];
        } else {
          return {
            id: value.id,
            campaignFieldId: value.campaignFieldId,
            value: value.value,
          };
        }
      }
    );

    const reqData = [...reqDataFormat];
    // console.log("req data: ", reqData);
    await updateCampaignFieldsValues({ data: reqData });
  }

  async function handleSave() {
    if (formStateKeywords?.errors?.keywords?.message) {
      return;
    }

    const reqData = { keywords: keywordsFormData.split("\n") };
    await updateKeywords(reqData);
    await updateCampaignFields();
    setIsEditEnabled(false);
  }

  function setInitialKeywords() {
    if (keywordsData && keywordsData.length !== 0) {
      const initialKeywords = keywordsData[0].keywords.join("\n");
      // setKeywordsVal(initialKeywords);
      setValueKeywords("keywords", initialKeywords);
      return;
    }

    setValueKeywords("keywords", "");
    // setKeywordsVal("");
  }

  function onError(mainFormErrors: FieldErrors) {
    setFocus(Object.keys(mainFormErrors)[0]);
  }

  function onKeywordError(keywordFormErrors: FieldErrors) {
    setFocus(Object.keys(keywordFormErrors)[0]);
  }

  useEffect(() => {
    setInitialKeywords();
    //eslint-disable-next-line
  }, [keywordsData?.length]);

  useEffect(() => {
    if (cannotUpdateCampaignFields) {
      toast.error("Unable to be update fields!");
    } else if (isFieldsUpdateSuccess) {
      toast.success("Fields successfully updated!");
    }
    // eslint-disable-next-line
  }, [cannotUpdateCampaignFields, isFieldsUpdateSuccess]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        hideProgressBar={false}
        theme="light"
        transition={Bounce}
      />
      <ContentContainer className="p-7">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit, onError)();
            handleSubmitKeywords(onSubmitKeywords, onKeywordError)();
          }}
        >
          <div className="flex flex-col rounded-lg  bg-white">
            <div className="flex self-end  ">
              {!isEditEnabled &&
                campaignDetailsData?.status &&
                campaignDetailsData?.status !== "active" && (
                  <RoundedButton
                    type="button"
                    onClick={() => {
                      if (
                        campaignDetailsData?.status &&
                        campaignDetailsData?.status !== "active"
                      ) {
                        setIsEditEnabled(true);
                      }
                    }}
                    className="py-2.5 px-4 max-w-fit ml-auto "
                    borderRadius="rounded-md"
                    text={
                      <p className=" text-sm text-white inline-flex items-center font-interRegular">
                        <EditIcon className="text-white fill-current mr-2 text-2xl " />
                        EDIT
                      </p>
                    }
                  />
                )}
            </div>
            <div className="grid  rounded-lg  grid-cols-1  ">
              {isEditEnabled ? (
                <CampaignFieldsRenderer
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  multiSelectFieldIds={multiSelectFieldIds}
                  campaignFieldVals={campaignFieldsFormData}
                  setCampaignFieldVals={setCampaignFieldVals}
                  isEditEnabled={isEditEnabled}
                  errors={formState.errors}
                  trigger={trigger}
                  campaignFields={campaignFields}
                />
              ) : (
                <CampaignFieldsReadMode
                  isUpdatingKeywordsFailed={isUpdatingKeywordsFailed}
                  numberOfKeywords={numberOfKeywords}
                  formattedKeywords={formattedKeywords}
                />
              )}
              {/* SEARCH KEYWORDS ON EDIT MODE */}
              {isEditEnabled && (
                <div className="flex order-1 flex-col mb-6 ">
                  <div className="flex items-center mb-2 ">
                    <h2 className="text-sm text-primary font-interSemiBold uppercase mr-1.5 ">
                      Search Keywords
                    </h2>
                    <Tooltip
                      Icon={TooltipIcon}
                      bodyText="A search keyword is what people type into search engines. The best keywords are based on relevance, volume of searches, how competitive it is to bid on, alignment to your goals and rank potential. We have selected these keywords for you based on the website you provided"
                      className="cursor-pointer w-4 h-4 text-gray-500 fill-current"
                    />
                  </div>
                  <h3 className="text-sm text-secondary max-w-fit  mb-1 tracking-tighter inline-flex   font-interRegular  ">
                    Selected keywords
                  </h3>
                  <div>
                    <div className="flex ">
                      <textarea
                        className={`${
                          formStateKeywords?.errors?.keywords?.message
                            ? "text-colorDelete border-[#d34638]"
                            : "border-[#40444f] text-primary"
                        } "xs:w-[18.75rem] sm:w-[27.5rem] text-sm  border-[#40444f] border h-44 font-interRegular hide-scrollbar resize-none  rounded  focus:outline-none p-2 pl-3"`}
                        {...registerKeywords("keywords", {
                          required: "Search keywords is required",
                        })}
                        placeholder="Enter keywords"
                      />
                      {formStateKeywords?.errors?.keywords && (
                        <p className="font-interSemiBold  ml-2 text-colorDelete mt-0 text-sm ">
                          {formStateKeywords.errors?.keywords?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* SAVE CANCEL BTNS ON EDIT MODE */}
            {isEditEnabled && (
              <div className="flex  items-center">
                <RoundedButton
                  className="py-2.5 px-4"
                  type="submit"
                  borderRadius="rounded-md"
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      <EditIcon className="text-white fill-current mr-2 text-2xl " />
                      {isKeywordsUpdatingNow ? "Saving..." : "Save"}
                    </p>
                  }
                />
                <p
                  onClick={() => {
                    setInitialKeywords();
                    reset({ ...initialCampaignFieldVals });
                    setCampaignFieldVals({ ...initialCampaignFieldVals });
                    setIsEditEnabled(false);
                  }}
                  className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
                >
                  Cancel
                </p>
              </div>
            )}

            {!isEditEnabled && (
              <div className="ml-auto flex ">
                <RoundedButton
                  onClick={() => setStep(1)}
                  type="button"
                  className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto mr-5"
                  borderRadius="rounded-md"
                  bgColor="#FFFFFF"
                  borderColor="#C1C9D2"
                  text={
                    <p className=" text-sm text-primary inline-flex items-center font-interRegular">
                      Previous
                    </p>
                  }
                />
                <RoundedButton
                  onClick={handleCampaignNext}
                  type="button"
                  isEnabled={!!isAllConfigFieldsFilled && !!textArea}
                  className="py-3 px-9 max-w-fit max-h-fit ml-auto mt-auto "
                  borderRadius="rounded-md"
                  text={
                    <p className=" text-sm text-white inline-flex items-center font-interRegular">
                      Next
                    </p>
                  }
                />
              </div>
            )}
          </div>
        </form>
      </ContentContainer>
    </div>
  );
};

export default Targeting;
