import { useMutation } from "@tanstack/react-query";
import { request } from "../../axios";
import { queryClient } from "../../../App";

export interface ICreativeImageCopySuggestionRequest {
  imageCopyId: number;
  instructions: string;
}
export interface ICreativeImageCopySuggestionResponse {
  id: number;
  imageCopyId: number;
  suggestedCopies: Array<string>;
}

export interface IUpdateCreativeImageCopySuggestionRequest {
  imageCopyId: number;
  selectedCopy: string;
}

function generateCopySuggestion(
  data: ICreativeImageCopySuggestionRequest
): Promise<ICreativeImageCopySuggestionResponse> {
  return request({
    method: "post",
    url: "image-copy-suggestion/image-copy",
    data,
  });
}

export function useCreativeCopySuggestion() {
  return useMutation({
    mutationFn: generateCopySuggestion,
  });
}

function updateCopySuggestion(data: IUpdateCreativeImageCopySuggestionRequest) {
  return request({
    method: "patch",
    url: `image-copy-suggestion/${data.imageCopyId}`,
    data,
  });
}

export function useUpdateCopySuggestion(campaignId: number | undefined) {
  return useMutation({
    mutationFn: updateCopySuggestion,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["creativeImageCopy", campaignId],
      });
    },
  });
}
