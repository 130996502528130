const PasswordStrengthItem: React.FC<{
  isChecked: boolean;
  message: string;
  width?: string;
  height?: string;
  fontSize?: string;
}> = ({ isChecked, message, width = "1rem", height = "1rem" }) => {
  return (
    <div className="flex items-center space-x-3">
      <div
        style={{
          width: `${width}`,
          height: `${height}`,
        }}
        className={`rounded-full ${
          isChecked ? "bg-[#52BAD6]" : "bg-[#E0E0E0]"
        }`}
      ></div>
      <p className="font-InterRegular text-primary text-sm leading-6">
        {message}
      </p>
    </div>
  );
};

export default PasswordStrengthItem;
