import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { request } from "../axios";
import {
  IAddAdvertiserReqData,
  IAdvertisersData,
  IUpdateAdvertiserDataRequest,
  TPaginatedDataResponse,
} from "../../interfaces";
import { queryClient } from "../../App";

export interface IAddAdvertiserDataResponse {
  createdAdvertiser: {
    displayName: string;
    email: string;
    phoneNumber: string;
    photoUrl: string;
    industryId: number;
    id: number;
    tone: string;
    createdAt: Date;
    updatedAt: Date;
  };
  isFieldsFilled: boolean;
}
/* ADD a new advertiser */
const createAdvertiser = (
  data: IAddAdvertiserReqData
): Promise<IAddAdvertiserDataResponse> => {
  return request({
    url: "advertiser",
    method: "post",
    data,
  });
};
export const useCreateAdvertiser = () =>
  useMutation<IAddAdvertiserDataResponse, Error, IAddAdvertiserReqData>({
    mutationFn: createAdvertiser,
    onMutate() {
      queryClient.invalidateQueries({
        queryKey: ["advertisers"],
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["advertisers"],
      });
    },
  });

/* GET all advertisers */
const getAllAdvertisers = ({
  page = 0,
  limit = 10,
}: {
  page: number;
  limit: number;
}): Promise<TPaginatedDataResponse<IAdvertisersData>> => {
  return request({
    url: `advertiser?page=${page}&limit=${limit}`,
    method: "get",
  });
};

export const useAllAdvertisers = ({
  page = 0,
  limit = 10,
}: {
  page: number;
  limit: number;
}) => {
  return useQuery({
    queryKey: ["advertisers", page],
    queryFn: () => getAllAdvertisers({ page, limit }),
    placeholderData: keepPreviousData,
  });
};

/* GET single advertiser */
const getAdvertiser = (
  advertiserId: number | null
): Promise<IAdvertisersData> => {
  return request({
    url: `advertiser/${advertiserId}`,
    method: "get",
  });
};

export const useAdvertiser = (advertiserId: number | null) => {
  return useQuery({
    queryKey: ["advertiser", advertiserId],
    queryFn: () => getAdvertiser(advertiserId),
    enabled: !!advertiserId,
  });
};

/* UPDATE single advertiser */
export interface IUpdateAdvertiserArgs {
  advertiserId: number | undefined;
  data: IUpdateAdvertiserDataRequest;
}

const updateAdvertiser = ({ advertiserId, data }: IUpdateAdvertiserArgs) => {
  return request({
    url: `advertiser/${advertiserId}`,
    method: "patch",
    data: data,
  });
};

export const useUpdateAdvertiser = (advertiserId: number | undefined) => {
  return useMutation<IAddAdvertiserDataResponse, Error, any>({
    mutationFn: updateAdvertiser,
    onSuccess: () => {
      // const advertiserIdString = String(advertiserId);
      queryClient.invalidateQueries({
        queryKey: ["advertiser", advertiserId],
      });

      queryClient.invalidateQueries({
        queryKey: ["advertisers"],
      });
    },
  });
};

/* DELETE Advertiser */
const deleteAdvertiser = (advertiserId: number | undefined) => {
  return request({
    url: `advertiser/${advertiserId}`,
    method: "delete",
  });
};

export const useRemoveAdvertiser = () => {
  return useMutation({
    mutationFn: deleteAdvertiser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["advertisers"],
      });
    },
  });
};
