import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "../../../layouts/main";

import FileUpload from "../../../components/imageUpload";
import RoundedButton from "../../../components/roundedButton";
import ContentContainer from "../../../components/contentContainer";
import TopContent from "../../../components/topContent";

import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon-mui.svg";

import { useUploadPhoto } from "../../../services/user";
import { useAddAsset } from "../../../services/website-assets";

import { handleFileType } from "../../../utils/handleFileType";
import { formatFileName } from "../../../utils/formatUploadedFileName";

const Index = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const idToNumber = id ? parseInt(id) : null;

  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<FormData[]>([]);
  const [filesForPreview, setFilesForPreview] = useState<File[]>([]);
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]);

  const {
    mutateAsync: uploadPhotoToGCP,
    isError: isPhotoUploadError,
    isPending: isPhotoUploadPending,
  } = useUploadPhoto();

  const {
    isPending: isAddingNewAssetPending,
    mutateAsync: addNewAsset,
    isError: isAddingNewAssetError,
    // isSuccess: isAddingNewAssetSuccess,
  } = useAddAsset(idToNumber);

  async function processFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const fileTypeIsValid = await handleFileType(formData, undefined, "file");
    if (fileTypeIsValid) {
      setFiles((prev) => [...prev, formData]);
      setFilesForPreview((prev) => [...prev, file]);
      return;
    }
    alert(
      "Please select a valid image file (jpeg, jpg, svg, png, avif, gif, bmp, webp)."
    );
  }

  async function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const newFilesForPreview = Array.from(e.dataTransfer.files).map(
        formatFileName
      );
      for (const file of newFilesForPreview) {
        await processFile(file);
      }
      e.dataTransfer.clearData();
    }
  }

  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const newFilesForPreview = Array.from(e.target.files).map(formatFileName);
      for (const file of newFilesForPreview) {
        await processFile(file);
      }
    }
  }

  function handleRemoveFiles(fileName: string | undefined) {
    if (fileName) {
      setFiles((prev) => {
        const newData = prev.filter((formData) => {
          const file = formData.get("file") as File;
          return file.name !== fileName;
        });
        return newData;
      });

      setFilesForPreview((prev) => {
        const newData = prev.filter((file) => file.name !== fileName);
        return newData;
      });

      setImagePreviewUrls((prev) => {
        const newData = prev.filter((_, i) => {
          const correspondingFile = filesForPreview[i];
          return correspondingFile.name !== fileName;
        });
        return newData;
      });
    } else {
      setFiles([]);
      setFilesForPreview([]);
      setImagePreviewUrls([]);
    }
  }

  async function handleSavePhoto() {
    if (
      files.length > 0 &&
      filesForPreview.length > 0 &&
      id &&
      filesForPreview.length > 0
    ) {
      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileForPreview = filesForPreview[i];
          const result = await uploadPhotoToGCP(file);
          if (result) {
            const requestData = {
              websiteId: parseInt(id),
              name: fileForPreview.name,
              imageUrl: result.url,
              isExcluded: false,
            };
            await addNewAsset(requestData);
          }
        }
        navigate(-1);
        handleRemoveFiles(undefined);
      } catch (error) {
        console.error("Uploding new asset failed:", error);
      }
    }
  }

  useEffect(() => {
    if (filesForPreview.length > 0) {
      const previewUrls = filesForPreview.map((file) =>
        URL.createObjectURL(file)
      );
      setImagePreviewUrls(previewUrls);
    }
  }, [filesForPreview]);

  // State to track the checkbox
  const [isChecked, setIsChecked] = useState<boolean>(false);

  // Handle checkbox change
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };
  return (
    <MainLayout showSidebarLogo showSidebar>
      <TopContent
        title="Add New Asset"
        subTitle="Digital assets for creative designs"
      />
      <ContentContainer className="p-8">
        <FileUpload
          handleDrop={handleDrop}
          mainText="Click to upload or drag and drop images"
          subText="SVG, PNG, JPG, or GIF..."
          handleChange={handleFileChange}
          renderedOnFileSelection={
            <div className="flex flex-col h-full justify-center">
              {filesForPreview.length > 0 && (
                <div className="flex flex-wrap">
                  {filesForPreview.map((fileForPreview, index) => (
                    <div
                      key={index}
                      className="flex relative px-6 items-center border-gray-300 py-2 rounded-md border-solid border m-2"
                    >
                      <div className="w-[100px] h-[80px] flex items-center relative">
                        {imagePreviewUrls[index] && (
                          <img
                            src={imagePreviewUrls[index]}
                            alt="Uploaded"
                            className="w-full h-full rounded-sm"
                          />
                        )}
                      </div>
                      <p className="ml-6 text-sm font-interRegular">
                        {fileForPreview?.name}
                      </p>
                      <div
                        onClick={() => handleRemoveFiles(fileForPreview?.name)}
                        className="absolute -right-1 -top-3 cursor-pointer"
                      >
                        <p className="text-colorDelete text-xl">X</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {isPhotoUploadError && (
                <p className="mt-4 text-colorDelete font-interRegular">
                  Failed to upload the photo!
                </p>
              )}
              {isAddingNewAssetError && (
                <p className="font-interRegular text-sm mt-4 text-colorDelete">
                  Failed to add new asset!
                </p>
              )}
            </div>
          }
          file={files}
          dragging={dragging}
          setDragging={setDragging}
        />

        <div className="flex flex-col  mt-10">
          <label className="flex items-center space-x-4">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="form-checkbox h-5 w-5 flex-shrink-0 text-[#d7edff]"
            />
            <span className="text-primary text-sm  max-w-[90%]  font-interRegular">
              By adding an image, you confirm that you own all legal rights to
              the image and have permission to share the image or use on your
              behalf in advertising or for other commercial purposes
            </span>
          </label>
        </div>

        <div className="flex  pt-10 items-center">
          <RoundedButton
            className="py-2.5 px-4"
            isEnabled={
              isChecked &&
              files.length > 0 &&
              filesForPreview.length > 0 &&
              filesForPreview.length > 0
            }
            onClick={() => {
              if (
                isChecked &&
                files.length > 0 &&
                filesForPreview.length > 0 &&
                id &&
                filesForPreview.length > 0
              ) {
                handleSavePhoto();
              }
            }}
            borderRadius="rounded-md"
            text={
              <p className=" text-sm text-white inline-flex items-center font-interRegular">
                <EditIcon className="text-white fill-current mr-2 text-2xl " />
                {isAddingNewAssetPending || isPhotoUploadPending
                  ? "Loading..."
                  : "SAVE"}
              </p>
            }
          />
          <p
            onClick={() => {
              navigate(-1);
              handleRemoveFiles(undefined);
            }}
            className="text-sm cursor-pointer font-interRegular ml-7 text-colorBlue"
          >
            Cancel
          </p>
        </div>
      </ContentContainer>
    </MainLayout>
  );
};

export default Index;
