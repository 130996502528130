import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  pdf,
  Font,
} from "@react-pdf/renderer";

import { saveAs } from "file-saver";

import SparkLogo from "../../../../assets/icons/spark-logo-white-text.png";

import { formatDateWithTime } from "../../../../utils/formatDateWithTime";
import { parseDefaultVal } from "../../../../utils/parseDefaultValues";

interface CampaignProps {
  setIsPDFPending?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  generalCampaignInfoArr: any[];
  advanceTargetingCampaignInfoArr: any[];
  geoTargetingCampaignInfoArr: any[];
  campaignName: string | undefined;
  campaignOwnerData: any;
  campaignData: any;
  campaignKeywordsFormatted: any;
  campaignDisplayImage: any;
  campaignDisplayImage2: any;
  campaignSocialImages: any;
  totalBudget: number | undefined;
  campaignSearchImages: any;
  pieChartImageSrc?: string;
  mapImageSrc?: string;
  legalText: string | null | undefined;
}
Font.register({
  family: "Inter",
  fonts: [
    {
      src: "/fonts/Inter/static/Inter-Light.ttf",
      fontWeight: 300,
    },
    {
      src: "/fonts/Inter/static/Inter-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/Inter/static/Inter-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "/fonts/Inter/static/Inter-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

export const SavePDF = async ({
  setIsPDFPending,
  generalCampaignInfoArr,
  advanceTargetingCampaignInfoArr,
  geoTargetingCampaignInfoArr,
  campaignName,
  campaignOwnerData,
  campaignData,
  totalBudget,
  campaignKeywordsFormatted,
  campaignDisplayImage,
  campaignDisplayImage2,
  campaignSocialImages,
  campaignSearchImages,
  pieChartImageSrc,
  mapImageSrc,
  legalText,
}: CampaignProps) => {
  setIsPDFPending && setIsPDFPending(true);

  const Navbar = (
    <View
      fixed={true}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#0e086a",
        height: 56,
        marginBottom: 15,
        paddingHorizontal: 16,
      }}
    >
      <View
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Image
            src={SparkLogo}
            style={{ width: 80, height: 80, objectFit: "contain" }}
          />
        </View>
      </View>
    </View>
  );

  const renderField = (fieldTitle: string, fieldVal: any) => (
    <View
      key={`${fieldTitle}-${fieldVal}`}
      style={{
        display: "flex",
        marginBottom: 3,
        marginRight: 20,
      }}
    >
      <Text style={{ fontSize: 10, fontWeight: 600, marginRight: 2 }}>
        {fieldTitle === "Address" ? "Where your ads will appear:" : fieldTitle}
      </Text>
      <Text style={{ fontSize: 10, fontWeight: 300 }}>
        {parseDefaultVal(fieldVal)}
      </Text>
    </View>
  );

  const sortedFields = geoTargetingCampaignInfoArr.sort((a, b) => {
    if (a.campaignFieldName === "Address") return -1;
    if (b.campaignFieldName === "Address") return 1;
    return 0;
  });

  const PDFDoc = (
    <Document>
      <Page size="A5" style={[styles.page]}>
        {Navbar}

        <View style={styles.section}>
          <Text style={styles.title}>Campaign Info</Text>
          <View style={styles.textWrapper}>
            <Text style={styles.subTitle}>Campaign for: </Text>
            <Text style={styles.text}>{campaignOwnerData?.displayName}</Text>
          </View>
          {generalCampaignInfoArr?.map((campaignFieldsObj, index) => {
            const fieldTitle = campaignFieldsObj.campaignFieldName;
            const fieldVal = campaignFieldsObj.campaignFieldName
              .toLocaleLowerCase()
              .includes("date")
              ? formatDateWithTime(campaignFieldsObj.value)
              : campaignFieldsObj.value;

            return (
              <View key={`${fieldVal}-s-${index}`} style={styles.textWrapper}>
                <Text style={styles.subTitle}>{fieldTitle}: </Text>
                <Text style={styles.text}>{fieldVal}</Text>
              </View>
            );
          })}

          <View style={styles.textWrapper}>
            <Text style={styles.subTitle}>Total Budget: </Text>
            <Text style={styles.text}>${totalBudget}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Allocation</Text>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View
              style={[styles.textWrapper, { width: "50%", marginRight: 10 }]}
            >
              <Text style={styles.text}>
                Strategic budget allocation is the cornerstone of success for
                any ad campaign that determines your ROI delivers maximum impact
                and returns. By carefully analyzing market trends, consumer
                behavior, and competitive landscapes, your budget allocation
                plan tailored to your unique business goal
              </Text>
            </View>
            <View style={[{ marginLeft: 30 }]}>
              <Image
                style={{ width: 80, height: 80, objectFit: "contain" }}
                src={pieChartImageSrc}
              />
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Targeting</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Text style={styles.text}>
              Our targeting strategy is engineered to pinpoint your ideal
              customers with surgical precision, ensuring every impression
              counts through a combination of advanced data analytics,
              demographic profiling, and psychographic segmentation, we've
              identified the audiences most interested in your business.
            </Text>
          </View>
          (
          <View
            style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
          >
            <Image
              style={{
                width: "600pt",
                height: 90,
                maxHeight: 90,
                objectFit: "contain",
              }}
              src={mapImageSrc}
            />
          </View>
          )
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {sortedFields?.map((campaignFieldsObj) => {
              const fieldTitle = campaignFieldsObj.campaignFieldName;
              const fieldVal = campaignFieldsObj.campaignFieldName
                .toLocaleLowerCase()
                .includes("date")
                ? formatDateWithTime(campaignFieldsObj.value)
                : campaignFieldsObj.value;

              return renderField(fieldTitle, fieldVal);
            })}
          </View>
          {advanceTargetingCampaignInfoArr.map((campaignFieldsObj, index) => {
            const fieldTitle = campaignFieldsObj.campaignFieldName;
            const fieldVal = campaignFieldsObj.campaignFieldName
              .toLocaleLowerCase()
              .includes("date")
              ? formatDateWithTime(campaignFieldsObj.value)
              : campaignFieldsObj.value;

            return (
              <View
                key={`${fieldVal}-${index}`}
                style={[styles.textWrapper, { marginTop: 5 }]}
              >
                <Text style={styles.subTitle}>{fieldTitle}: </Text>
                <Text style={styles.text}>{parseDefaultVal(fieldVal)}</Text>
              </View>
            );
          })}
          <View style={styles.textWrapperCol}>
            <Text style={[styles.subTitle, { marginVertical: 10 }]}>
              Search keywords:
            </Text>
            <Text style={[styles.text]}>{campaignKeywordsFormatted}</Text>
          </View>
          {legalText && (
            <View style={styles.textWrapperCol}>
              <Text style={[styles.subTitle, { marginVertical: 10 }]}>
                Legal
              </Text>
              <Text style={styles.text}>{legalText}</Text>
            </View>
          )}
        </View>

        <View break style={styles.section}>
          <Text style={[styles.title, { marginTop: 10 }]}>Sample Creative</Text>
          <Text style={[styles.text, { marginBottom: 5 }]}>
            The following creative concepts are designed to showcase some
            samples of how your brand would appear to a ad audience across
            different media channels. These are tailored to suit your unique
            brand identity and objectives and are optimized based on our
            industry experience to ensure it resonates with audiences.
          </Text>
          <View style={styles.imageContainer}>
            <View style={styles.column}>
              {campaignDisplayImage && (
                <Image src={campaignDisplayImage} style={styles.image} />
              )}
              {campaignSocialImages && (
                <Image src={campaignSocialImages} style={styles.image} />
              )}
            </View>
            <View style={styles.column}>
              {campaignDisplayImage2 && (
                <Image src={campaignDisplayImage2} style={styles.image} />
              )}
              {campaignSearchImages && (
                <Image src={campaignSearchImages} style={styles.image} />
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  try {
    const pdfBlob = await pdf(PDFDoc).toBlob();
    saveAs(pdfBlob, `${campaignName}-${campaignOwnerData?.displayName}.pdf`);
    setIsPDFPending && setIsPDFPending(undefined);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

const styles = StyleSheet.create({
  page: {
    paddingBottom: 12,
    fontFamily: "Inter",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
    flexWrap: "wrap",
  },
  textWrapperCol: { display: "flex", marginVertical: 5 },
  section: {
    paddingHorizontal: 10,
    marginBottom: 0,
  },
  title: {
    fontWeight: 700,
    color: "#40444f",
    paddingBottom: 5,
    fontSize: 14,
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 10,
    fontWeight: 600,
    marginRight: 2,
  },
  text: {
    fontWeight: 300,
    fontSize: 10,
    lineHeight: 1.5,
    marginBottom: 5,
    flexShrink: 1,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  column: {
    flexBasis: "48%", // Each column takes up 48% of the container width
    display: "flex",
    flexDirection: "column",
    marginBottom: "2%", // Margin between rows
  },
  image: {
    marginBottom: "2%", // Margin between images in the same column
  },
});
