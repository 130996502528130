import { useQuery } from "@tanstack/react-query";
import { request } from "../axios";

export interface IToneData {
  description: string;
  tone: string;
}

/* GET  advertiser tones */
const getAdvertiserTones = (): Promise<IToneData[]> => {
  return request({
    url: `advertiser/tones/tone`,
    method: "get",
  });
};

export const useAdvertiserTones = () => {
  return useQuery({
    queryKey: ["advertiserTones"],
    queryFn: getAdvertiserTones,
  });
};
