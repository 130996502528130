import React, { useState } from "react";

interface PieSlice {
  value: any;
  color: string;
  title?: string;
}

interface PieChartProps {
  data: PieSlice[];
  topContainer?: string;
  indicatorContainer?: string;
  circleContainer?: string;
}

const PieChart: React.FC<PieChartProps> = ({
  data,
  topContainer,
  indicatorContainer,
  circleContainer,
}) => {
  const total = data.reduce((acc, item) => acc + item.value, 0);
  const [hoveredSlice, setHoveredSlice] = useState<PieSlice | null>(null);

  const calculatePath = (
    slice: PieSlice,
    index: number,
    startAngle: number
  ) => {
    const x0 = 100;
    const y0 = 100;
    const r = 80;

    const angle = (slice.value / total) * 2 * Math.PI;
    const x = x0 + r * Math.sin(startAngle + angle);
    const y = y0 - r * Math.cos(startAngle + angle);

    const largeArcFlag = angle > Math.PI ? 1 : 0;

    const pathData = [
      `M${x0},${y0}`,
      `L${x0 + r * Math.sin(startAngle)},${y0 - r * Math.cos(startAngle)}`,
      `A${r},${r} 0 ${largeArcFlag},1 ${x},${y}`,
      "Z",
    ].join(" ");

    return (
      <path
        key={`p-${index}`}
        className="border border-solid border-white"
        d={pathData}
        fill={slice.color}
        onMouseEnter={() => setHoveredSlice(slice)}
        onMouseLeave={() => setHoveredSlice(null)}
      />
    );
  };

  const renderSlices = () => {
    let accumulatedAngle = 0;
    return data.map((slice, index) => {
      const path = calculatePath(slice, index, accumulatedAngle);
      const angle = (slice.value / total) * 2 * Math.PI;
      accumulatedAngle += angle;
      return path;
    });
  };

  return (
    <div className="flex w-full">
      <div
        className={`${
          topContainer ? topContainer : "flex flex-col space-y-3 w-full"
        }`}
      >
        {data.map((slice, index) => (
          <div
            key={`s-${index}`}
            className={`mt-7 ${
              indicatorContainer
                ? indicatorContainer
                : "flex  max-w-fit self-end"
            } `}
          >
            <div
              style={{ backgroundColor: slice.color }}
              className="w-6 h-6  rounded-sm text-gray-800 font-interSemiBold"
            ></div>
            <p className="text-xs ml-5  flex-1  flex-shrink-0 font-interRegular text-primary">
              {slice.title} ({slice.value}%)
            </p>
          </div>
        ))}
      </div>

      <div
        className={`${circleContainer ? circleContainer : "relative w-full"} `}
      >
        <svg width="250" height="250" viewBox="0 0 220 220" className="mx-auto">
          {data.length === 1 || data.some((slice) => slice.value === total) ? (
            <circle
              cx="100"
              cy="100"
              r="80"
              fill={
                data.find((slice) => slice.value === total)?.color ||
                data[0].color
              }
            />
          ) : (
            renderSlices()
          )}
        </svg>
        {hoveredSlice && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
            <div className="bg-white p-2 border border-gray-200 rounded shadow-lg">
              {hoveredSlice.title && (
                <div className="font-bold text-sm">
                  {hoveredSlice.title} {hoveredSlice.value.toFixed(1)}%
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PieChart;
