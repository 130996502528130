import { useRef, useState } from "react";

import { createPortal } from "react-dom";

const Index: React.FC<{
  className?: string;
  bodyText: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}> = ({
  className,
  bodyText,
  Icon,
}: {
  className?: string;
  bodyText: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const spanRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const tooltipTimeout = useRef<number | null>(null);

  const paragraphs = bodyText.split("\n").filter((text) => text.trim() !== "");

  function renderTooltip() {
    if (!showTooltip || !spanRef.current) return null;

    const rect = spanRef.current.getBoundingClientRect();

    return createPortal(
      <div
        ref={tooltipRef}
        className="custom-tooltip"
        style={{
          position: "absolute",
          top: rect.bottom + window.scrollY + 10,
          left: rect.left + window.scrollX - 5,
          zIndex: 9999,
        }}
      >
        <div className="tooltip-arrow"></div>
        {paragraphs.map((paragraph, index) => (
          <div key={index}>
            <p className="text-white font-interRegular text-xs">
              {paragraph.trim()}
            </p>
            {/* {index < paragraphs.length - 1 && <br />} */}
          </div>
        ))}
        {/* <p className="text-white font-interRegular text-xs">{bodyText}</p> */}
      </div>,
      document.body
    );
  }

  return (
    <div ref={spanRef} className="relative">
      <Icon
        onMouseEnter={() => {
          tooltipTimeout.current = window.setTimeout(() => {
            setShowTooltip(true);
          }, 500);
        }}
        onMouseLeave={() => {
          if (tooltipTimeout.current) {
            clearTimeout(tooltipTimeout.current);
          }
          setShowTooltip(false);
        }}
        onClick={() => setShowTooltip(!showTooltip)}
        className={className}
      />
      {renderTooltip()}
    </div>
  );
};

export default Index;
