import { useMutation } from "@tanstack/react-query";
import { request } from "../axios";

function getThemeSuggestion({
  data,
}: {
  data: {
    month: number;
    industryId: number;
  };
}) {
  return request({
    method: "post",
    url: "/theme-suggestion/suggest",
    data: data,
  });
}

export function useUpdateThemeSuggestion() {
  return useMutation({
    mutationFn: getThemeSuggestion,
  });
}
