import React from "react";

import { UseFormRegister } from "react-hook-form";

import Input from "../input/inputWithToggle";

const SearchBar = ({
  label,
  urlBarValue,
  setUrlBarValue,
  urlErrMesg,
  register,
  reactHooksFormEnabled,
  requiredMessage,
  pattern,
  name,
}: {
  urlBarValue?: string;
  setUrlBarValue?: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  urlErrMesg: string | undefined;
  register?: UseFormRegister<any>;
  reactHooksFormEnabled?: boolean;
  requiredMessage?: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  name: string;
}) => {
  return (
    <>
      <Input
        type="text"
        name={name}
        label={label}
        register={register}
        pattern={pattern}
        requiredMessage={requiredMessage}
        validation={urlErrMesg}
        validationClassName="text-colorDelete text-sm  ml-2"
        reactHooksFormEnabled={true}
        className={`${urlErrMesg && "border-[#D34638]"}`}
      />
    </>
  );
};

export default SearchBar;
