import React, { useState } from "react";

import { UseFormRegister } from "react-hook-form";

import HideIcon from "../../assets/icons/hide.png";
import HiddenIcon from "../../assets/icons/hidden.png";

interface IFloatingLabelInput {
  id: string;
  name: string;
  label: string;
  type?: string;
  value?: string;
  reactHooksFormEnabled?: boolean;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  pattern?: {
    value: RegExp;
    message: string;
  };
  requiredMessage?: string;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  focusedField: string | null;
  setFocusedField: (fieldName: string | null) => void;
  containerDivClassName?: string;
  errorMessage?: string;
}

const FloatingLabelInput: React.FC<IFloatingLabelInput> = ({
  id,
  name,
  label,
  type = "text",
  value,
  onInputChange,
  focusedField,
  reactHooksFormEnabled,
  disabled,
  register,
  pattern,
  requiredMessage,
  setFocusedField,
  containerDivClassName,
  errorMessage,
}) => {
  const isFocused = focusedField === name;

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const handleFocus = () => setFocusedField(name);
  const handleBlur = () => setFocusedField(null);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <div className={`relative pt-5 pb-0 ${containerDivClassName}`}>
      <label
        htmlFor={id}
        className={`absolute pointer-events-none font-interRegular left-3 transition-all ${
          isFocused || value
            ? "top-6 text-xs text-gray-600"
            : "top-[48%] text-sm text-gray-400"
        }`}
      >
        {label}
      </label>
      {reactHooksFormEnabled && register ? (
        <>
          <input
            type={
              type === "password"
                ? isPasswordVisible
                  ? "text"
                  : "password"
                : type
            }
            className={`w-full font-ManropeRegular px-3  pt-5 pb-1 outline-none  text-base  border border-solid ${
              errorMessage ? "border-red-500" : "border-gray-300"
            } rounded-sm focus:outline-none focus:border-blue-500`}
            {...register(name, { required: requiredMessage, pattern: pattern })}
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {type === "password" && (
            <div className="absolute w-full top-1/2 ">
              <img
                src={isPasswordVisible ? HideIcon : HiddenIcon}
                alt="password visibility"
                className="absolute right-3.5 top-0 cursor-pointer h-4 w-4 "
                onClick={togglePasswordVisibility}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <input
            id={id}
            name={name}
            type={
              type === "password"
                ? isPasswordVisible
                  ? "text"
                  : "password"
                : type
            }
            value={value}
            onChange={onInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="w-full font-ManropeRegular px-3  pt-5 pb-1 outline-none  text-base  border border-solid border-gray-300 rounded-sm focus:outline-none focus:border-blue-500"
          />

          {type === "password" && (
            <div className="relative w-full -top-7">
              <img
                src={isPasswordVisible ? HideIcon : HiddenIcon}
                alt="password visibility"
                className="absolute right-3.5 top-0 cursor-pointer h-4 w-4 "
                onClick={togglePasswordVisibility}
              />
            </div>
          )}
        </>
      )}

      {errorMessage && (
        <div role="alert" className="font-interSemiBold   text-red-400 text-sm">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default FloatingLabelInput;
