import { useState } from "react";

import MainLayout from "../../layouts/main";

import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "../../contexts/auth";

import EditProfile from "../../containers/profile/editProfile";
import DisplayProfile from "../../containers/profile";
import ChangePassword from "../../containers/profile/changePassword";

import { ProfileContent } from "../../interfaces";

import { useGetSelf, useUpdateSelf } from "../../services/user";

const Profile = () => {
  const {
    isSuccess: isUpdateUserPasswordSuccess,
    isError: isUpdateUserPasswordError,
    errorMessage: updateUserPasswordErrorMessage,
    updateUserPassword,
    isLoading: isUpdateUserPasswordLoading,
  } = useAuth();

  const [content, setContent] = useState<ProfileContent>("displayProfile");

  const { data: getSelfData, isError: getSelfError } = useGetSelf();

  const {
    mutateAsync: updateUserProfile,
    isPending: isUpdateUserPending,
    isSuccess: isEditUserSuccess,
  } = useUpdateSelf();

  const switchContent = (content: ProfileContent) => {
    setContent(content);
  };

  return (
    <MainLayout showSidebarLogo showSidebar>
      {getSelfError && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Something went wrong...
        </div>
      )}
      <div className=" space-y-5">
        {content === "editProfile" ? (
          <EditProfile
            isUpdateUserPending={isUpdateUserPending}
            updateUserProfile={updateUserProfile}
            data={getSelfData}
            onClickGoBack={switchContent}
          />
        ) : content === "changePassword" ? (
          <ChangePassword
            updateUserPasswordErrorMessage={updateUserPasswordErrorMessage}
            isUpdateUserPasswordError={isUpdateUserPasswordError}
            updateUserPassword={updateUserPassword}
            isUpdateUserPasswordLoading={isUpdateUserPasswordLoading}
            isUpdateUserPasswordSuccess={isUpdateUserPasswordSuccess}
            data={getSelfData}
            onClickGoBack={switchContent}
          />
        ) : (
          <DisplayProfile
            data={getSelfData}
            isEditUserSuccess={isEditUserSuccess}
            onClickEdit={switchContent}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default Profile;
