import Wizard from "../../containers/wizard/steps";

import MainLayout from "../../layouts/main";

const Index = () => {
  return (
    <MainLayout showSidebarLogo showSidebar showWizard>
      <Wizard />
    </MainLayout>
  );
};

export default Index;
