export function parseDomainBeforeVeloxi(url: string) {
  // const subdomainParts = url.split(".");
  // if (subdomainParts) {
  //   return subdomainParts[0];
  // }
  let hostname: string;
  try {
    hostname = new URL(url).hostname;
  } catch (error) {
    hostname = url;
  }

  const subdomainParts = hostname.split(".");

  if (subdomainParts.length > 0) {
    return subdomainParts[0];
  }
  return "not-found";
}
