import MainLayout from "../../layouts/main";

import AdvertiserBusinessProfile from "../../containers/advertisers/advertiserGoogleBusinessAcc";

const BusinessProfile = () => {
  return (
    <MainLayout showSidebar showSidebarLogo>
      <AdvertiserBusinessProfile />
    </MainLayout>
  );
};

export default BusinessProfile;
